// import * as bootstrap from 'bootstrap'
import Modal from 'bootstrap/js/dist/modal'
import { signIn, signOutUser } from './server/firebase.js'
import { tagifyElements, inputElements, maskElements } from './app.js'
import { map } from './constants.js'

const loginBtn = document.getElementById('loginBtn')
const spinner = loginBtn.querySelector('span.spinner-border')
export async function login (e, form) {
  e.preventDefault()
  e.stopPropagation()

  const emailEl = form.loginMail
  const passwordEl = form.loginPassword
  const isValidEmail = validateMail(emailEl)
  const isValidPassword = validatePassword(passwordEl)

  if (!isValidEmail || !isValidPassword) {
    enableLoginBtn()
    console.error('Введите корректные данные')
  } else {
    const mail = emailEl.value.trim()
    const password = passwordEl.value.trim()
    const user = await signIn(mail, password)
    if (user) {
      console.log('Логин успешен:', user.email)
      return user
    } else {
      console.error('Логин неудачен')
      enableLoginBtn()
      updateValidationState(emailEl, false, '')
      updateValidationState(passwordEl, false, 'Неправильная почта или пароль')
    }
  }
}

function isEmailValid (email) {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/
  return emailRegex.test(email)
}

export function validateMail (emailInput) {
  const email = emailInput.value.trim()
  if (!email) {
    updateValidationState(emailInput, false, 'Введите почту')
    return false
  } else if (!isEmailValid(email)) {
    updateValidationState(emailInput, false, 'Введите почту в правильном формате')
    return false
  } else {
    updateValidationState(emailInput, true)
    return true
  }
}

function validatePassword (passwordEl) {
  const password = passwordEl.value.trim()
  if (!password) {
    updateValidationState(passwordEl, false, 'Введите пароль')
    return false
  } else {
    updateValidationState(passwordEl, true)
    return true
  }
}

function updateValidationState (element, isValid, message) {
  const feedbackElement = element.parentNode.querySelector('.invalid-feedback')
  if (isValid) {
    element.classList.remove('is-invalid')
    element.classList.add('is-valid')
    feedbackElement.textContent = ''
  } else {
    element.classList.add('is-invalid')
    element.classList.remove('is-valid')
    feedbackElement.textContent = message
  }
}

function enableLoginBtn () {
  loginBtn.removeAttribute('disabled')
  spinner.classList.add('d-none')
}

export function handleLogoutClick (e) {
  let isLogOutClicked = false
  const modalElement = document.getElementById('logoutModal')
  const modal = new Modal(modalElement)
  const onModalHidden = async () => {
    if (!isLogOutClicked) return
    try {
      const result = await signOutUser()
      console.log(result)
    } catch (error) {
      console.log(`Ошибка при выходе: ${error}`)
    } finally {
      modalElement.removeEventListener('hidden.bs.modal', onModalHidden)
    }
  }
  modalElement.addEventListener('hidden.bs.modal', onModalHidden)
  modal.show()
  function confirmLogout () {
    isLogOutClicked = true
    modal.hide()
  }

  const confirmExitBtn = document.getElementById('confirmExitBtn')
  confirmExitBtn.onclick = confirmLogout
}

export function setPermissions (role) {
  const isEditor = role === map.roles.editor
  toggleReadOnlyState(inputElements, 'input', !isEditor)
  toggleReadOnlyState(tagifyElements, 'tagify', !isEditor)
  toggleReadOnlyState(maskElements, 'mask', !isEditor)
  toggleButtonsVisibility(['save-btn', 'delete-btn', 'addBtn'], isEditor)
}

function toggleReadOnlyState (elements, type, readonly) {
  Object.values(elements).forEach(element => {
    switch (type) {
      case 'input':
        if (element.hasAttribute('contenteditable')) {
          element.setAttribute('contenteditable', !readonly)
        } else {
          if (readonly) {
            element.setAttribute('readonly', 'readonly')
          } else {
            element.removeAttribute('readonly')
          }
        }
        break
      case 'tagify':
        if (element.settings.role.elementId !== map.marks.elementId) {
          element.setReadonly(readonly)
        }
        break

      case 'mask':
        if (readonly) {
          element.iMask.el.input.setAttribute('readonly', 'readonly')
        } else {
          element.iMask.el.input.removeAttribute('readonly')
        }
        break
    }
  })
}

function toggleButtonsVisibility (buttonIds, show) {
  buttonIds.forEach(id => {
    const button = document.getElementById(id)
    if (button) {
      button.classList.toggle('d-none', !show)
    }
  })
}
