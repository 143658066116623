import Excel from 'exceljs'

import { map } from './constants.js'
import { table } from './uiControl/tabulatorControl.js'
import { tagifyElements } from './app.js'
import { readUsersFromFB } from './server/firebase.js'

export async function exportToExcel () {
  const workbook = new Excel.Workbook()
  const fileName = 'Каталог лекций'
  setWorkbookProperties(workbook)

  const sheets = createSheets(workbook)
  const {
    mainSheet, typeSheet, audioStatusSheet, videoStatusSheet, handlerSheet, placeSheet, sadhuSheet,
    scriptureSheet, usersSheet
  } = sheets

  setMainSheetColumns(mainSheet)
  setColorSheetsColumns(typeSheet, audioStatusSheet, videoStatusSheet)
  setSingleSheetsColumns(handlerSheet, placeSheet, sadhuSheet)
  setScriptureSheetColumns(scriptureSheet)
  setUserSheetColumns(usersSheet)

  addDataToMainSheet(mainSheet)
  addDataToAdditionalSheets(typeSheet, audioStatusSheet, videoStatusSheet, handlerSheet, placeSheet, sadhuSheet,
    scriptureSheet)
  await addDataToUserSheet(usersSheet)

  setDataValidation(mainSheet)
  setConditionalFormatting(mainSheet)

  setAutoFilter(mainSheet)
  setColumnStylesToMainsheet(mainSheet)

  await saveWorkbook(workbook, fileName)
}

function setWorkbookProperties (workbook) {
  workbook.created = new Date()
  workbook.modified = new Date()
  workbook.lastPrinted = new Date()
}

function createSheets (workbook) {
  return {
    mainSheet: workbook.addWorksheet('Каталог'),
    typeSheet: workbook.addWorksheet('Тип'),
    audioStatusSheet: workbook.addWorksheet('Статус аудио'),
    videoStatusSheet: workbook.addWorksheet('Статус видео'),
    handlerSheet: workbook.addWorksheet('Обработчик'),
    placeSheet: workbook.addWorksheet('Место'),
    sadhuSheet: workbook.addWorksheet('Садху'),
    scriptureSheet: workbook.addWorksheet('Писания'),
    usersSheet: workbook.addWorksheet('Пользователи')
  }
}

function setMainSheetColumns (mainSheet) {
  const columns = table.columnManager.columns
  mainSheet.columns = columns.map(col => ({
    header: col.getDefinition().title,
    key: col.getField(),
    width: col.getDefinition().width / 7
  }))

  formatHeaderRow(mainSheet)
}

function setColorSheetsColumns (...sheets) {
  const columns = [
    { header: 'Название', key: 'value', width: 30 },
    { header: 'Цвет', key: 'color', width: 30 },
    { header: 'Фон', key: 'bgColor', width: 30 }
  ]

  sheets.forEach(sheet => {
    sheet.columns = columns
    formatHeaderRow(sheet)
  })
}

function setSingleSheetsColumns (...sheets) {
  const columns = [
    { header: 'Название', key: 'value', width: 30 }
  ]

  sheets.forEach(sheet => {
    sheet.columns = columns
    formatHeaderRow(sheet)
  })
}

function setScriptureSheetColumns (scriptureSheet) {
  const columns = [
    { header: 'Название', key: 'value', width: 60 },
    { header: 'Автор', key: 'author', width: 30 }
  ]

  scriptureSheet.columns = columns
  formatHeaderRow(scriptureSheet)
}

function setUserSheetColumns (usersSheet) {
  const columns = [
    { header: 'Почта', key: 'email', width: 30 },
    { header: 'Роль', key: 'role', width: 30 },
    { header: 'UID', key: 'uid', width: 30 }
  ]

  usersSheet.columns = columns
  formatHeaderRow(usersSheet)
}

function addDataToMainSheet (mainSheet) {
  table.getData('active').forEach(row => {
    const rowToExcel = {
      date: createDateWithoutTime(row.date),
      [map.type.fieldName]: row.type.value,
      [map.audioStatus.fieldName]: row.audioStatus.value,
      [map.videoStatus.fieldName]: row.videoStatus.value,
      [map.handler.fieldName]: row.handler?.value ?? '',
      [map.place.fieldName]: row.place?.value ?? '',
      [map.author.fieldName]: row.author.value,
      [map.scriptures.fieldName]: row.scriptures ? row.scriptures.map(scripture => scripture.value).join(', ') : '',
      [map.reader.fieldName]: row.reader?.value ?? '',
      comment: row.comment,
      title: row.title,
      description: row.description,
      [map.tags.fieldName]: row.tags ? row.tags.map(tag => tag.value).join(', ') : '',
      [map.marks.fieldName]: row.marks ? row.marks.map(mark => mark.value).join(', ') : '',
      duration: row.duration,
      youtube: row.youtube,
      advayta: row.advayta
    }
    mainSheet.addRow(rowToExcel)
  })
}

function addDataToAdditionalSheets (typeSheet, audioStatusSheet, videoStatusSheet, handlerSheet, placeSheet, sadhuSheet,
  scriptureSheet
) {
  addDataToColorSheet(typeSheet, tagifyElements.type.whitelist)
  addDataToColorSheet(audioStatusSheet, tagifyElements.audioStatus.whitelist)
  addDataToColorSheet(videoStatusSheet, tagifyElements.videoStatus.whitelist)
  addDataToSingleSheet(handlerSheet, tagifyElements.handler.whitelist)
  addDataToSingleSheet(placeSheet, tagifyElements.place.whitelist)
  addDataToSingleSheet(sadhuSheet, tagifyElements.author.whitelist)
  addDataToScriptureSheet(scriptureSheet, tagifyElements.scriptures.whitelist)
}

function addDataToColorSheet (sheet, data) {
  data.forEach(item => {
    sheet.addRow({
      value: item.value,
      color: item.color,
      bgColor: item.bgColor
    })
  })
}
function addDataToSingleSheet (sheet, data) {
  data.forEach(item => {
    sheet.addRow({
      value: item.value
    })
  })
}

async function addDataToUserSheet (usersSheet) {
  const usersList = await readUsersFromFB()
  if (!usersList) {
    console.log('Список пользователей пуст')
    return null
  }
  Object.values(usersList).forEach(user => {
    usersSheet.addRow({
      email: user.email,
      role: user.role,
      uid: user.uid
    })
  })
}

function addDataToScriptureSheet (sheet, data) {
  data.forEach(item => {
    sheet.addRow({
      value: item.value,
      author: item.author
    })
  })
}

function setDataValidation (mainSheet) {
  setColumnDataValidation(mainSheet, map.type.fieldName, 'Тип', tagifyElements.type.whitelist.length)
  setColumnDataValidation(mainSheet, map.audioStatus.fieldName, 'Статус аудио', tagifyElements.audioStatus.whitelist.length)
  setColumnDataValidation(mainSheet, map.videoStatus.fieldName, 'Статус видео', tagifyElements.videoStatus.whitelist.length)
  setColumnDataValidation(mainSheet, map.handler.fieldName, 'Обработчик', tagifyElements.handler.whitelist.length)
  setColumnDataValidation(mainSheet, map.place.fieldName, 'Место', tagifyElements.place.whitelist.length)
  setColumnDataValidation(mainSheet, map.author.fieldName, 'Садху', tagifyElements.author.whitelist.length)
  setColumnDataValidation(mainSheet, map.scriptures.fieldName, 'Писания', tagifyElements.scriptures.whitelist.length)
  setColumnDataValidation(mainSheet, map.reader.fieldName, 'Садху', tagifyElements.reader.whitelist.length)
}

function setColumnDataValidation (sheet, fieldName, sheetName, dataLength) {
  const range = `'${sheetName}'!$A$2:$A$${dataLength + 1}`
  sheet.getColumn(fieldName).eachCell((cell, rowNumber) => {
    if (rowNumber > 1) {
      cell.dataValidation = {
        type: 'list',
        allowBlank: false,
        formulae: [range],
        showErrorMessage: true,
        errorStyle: 'error',
        errorTitle: 'Неверное значение',
        error: 'Выберите значение из списка.'
      }
    }
  })
}

function setConditionalFormatting (mainSheet) {
  setConditionalFormattingForColumn(mainSheet, map.type.fieldName, tagifyElements.type.whitelist)
  setConditionalFormattingForColumn(mainSheet, map.audioStatus.fieldName, tagifyElements.audioStatus.whitelist)
  setConditionalFormattingForColumn(mainSheet, map.videoStatus.fieldName, tagifyElements.videoStatus.whitelist)
}

function setConditionalFormattingForColumn (sheet, fieldName, data) {
  const columnLetter = getColumnLetter(sheet, fieldName)
  data.forEach(item => {
    const argbFgColor = hslToArgb(item.bgColor)
    const argbColor = hslToArgb(item.color)
    sheet.addConditionalFormatting({
      ref: `${columnLetter}2:${columnLetter}1048576`,
      rules: [{
        type: 'expression',
        formulae: [`INDIRECT("${columnLetter}"&ROW())="${item.value}"`],
        style: {
          fill: { type: 'pattern', pattern: 'solid', bgColor: { argb: argbFgColor } },
          font: { color: { argb: argbColor } }
        }
      }]
    })
  })
}

function setAutoFilter (sheet) {
  sheet.autoFilter = {
    from: 'A1',
    to: 'Z1'
  }
}

function setColumnStylesToMainsheet (sheet) {
  setStyleToColumn(sheet, map.type.fieldName)
  setStyleToColumn(sheet, map.audioStatus.fieldName)
  setStyleToColumn(sheet, map.videoStatus.fieldName)
  setStyleToColumn(sheet, map.handler.fieldName)
  setStyleToColumn(sheet, map.author.fieldName)
  setStyleToColumn(sheet, 'duration')
  setStyleToColumn(sheet, 'date')
  sheet.getColumn('key').hidden = true
  sheet.getColumn('date').style.numFmt = 'yyyy.mm.dd'
}

async function saveWorkbook (workbook, fileName) {
  const data = await workbook.xlsx.writeBuffer()
  const blob = new Blob([data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  })
  const url = window.URL.createObjectURL(blob)
  const anchor = document.createElement('a')
  anchor.href = url
  anchor.download = `${fileName}.xlsx`
  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor)
  window.URL.revokeObjectURL(url)

  console.log('Файл был успешно создан и предложен для скачивания!')
}
// Задает выравнивание по всей колонке по центру и по середине
function setStyleToColumn (mainSheet, fieldName) {
  const columnLetter = getColumnLetter(mainSheet, fieldName)
  // Получаем колонку по букве и применяем стиль
  const statusColumn = mainSheet.getColumn(columnLetter)
  statusColumn.alignment = { vertical: 'middle', horizontal: 'center' }
}

function formatHeaderRow (sheet) {
  //  format header
  const headerRow = sheet.getRow(1)
  headerRow.alignment = {
    vertical: 'middle',
    horizontal: 'center',
    wrapText: true
  }
  headerRow.height = 25
  headerRow.font = { bold: true, color: { argb: '003761' } }
  headerRow.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'e5f4ff' } }
}

function createDateWithoutTime (dateString) {
  const [year, month, day] = dateString.split('.').map(Number)
  return new Date(Date.UTC(year, month - 1, day))
}

// Function to find column letter by key
function getColumnLetter (sheet, key) {
  // Find the column index by the key
  const column = sheet.columns.find(col => col.key === key)
  if (!column) {
    throw new Error('Column not found')
  }

  // ExcelJS columns array is 0-based, Excel columns are 1-based
  const colIndex = sheet.columns.indexOf(column) + 1

  // Convert column index to letter
  let letter = ''
  let temp = colIndex
  while (temp > 0) {
    const remainder = (temp - 1) % 26
    letter = String.fromCharCode(65 + remainder) + letter
    temp = (temp - remainder - 1) / 26
  }

  return letter
}

function hslToRgb (h, s, l) {
  h /= 360
  s /= 100
  l /= 100

  const a = s * Math.min(l, 1 - l)
  const f = n => {
    const k = (n + h * 12) % 12
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
    return Math.round(255 * color)
  }
  return { r: f(0), g: f(8), b: f(4) }
}

function hslToArgb (hslColor) {
  const match = hslColor.match(/hsl\((\d+\.?\d*), (\d+\.?\d*)%, (\d+\.?\d*)%\)/)
  if (!match) {
    console.error('Invalid HSL color format')
    return null
  }

  const [h, s, l] = match.slice(1).map(Number)
  const { r, g, b } = hslToRgb(h, s, l)
  return `FF${[r, g, b].map(x => x.toString(16).padStart(2, '0').toUpperCase()).join('')}`
}
