import Tagify from '@yaireo/tagify'
export function createSearchTagify (mapLookup, el) {
  const tagify = new Tagify(el, getTagifyOptions(mapLookup))
  return tagify
}

function getTagifyOptions (arrayList) {
  // Проверяем, есть ли хотя бы один объект с полем 'author'
  const hasAuthor = arrayList.some(item => item.author)
  const options = {
    whitelist: arrayList, //  список для dropdown list
    addTagOnBlur: false,
    addTagOn: ['enter'],
    enforceWhitelist: true,
    autoComplete: {
      rightKey: true // If true, when → is pressed, use the suggested value to create a tag, else just auto-completes the input.
    },
    dropdown: {
    //   classname: 'dropdown_inside_filter',
      enabled: 0, // show the dropdown immediately on focus
      maxItems: 10,
      highlightFirst: true,
      // Если хотя бы один объект содержит поле 'author', добавляем searchKeys
      ...(hasAuthor && { searchKeys: ['value', 'author'] })
    },
    templates: {
      closeOnSelect: true,
      dropdownItem: suggestionItemTemplate // Используйте кастомный шаблон для элементов выпадающего списка
    },
    editTags: false

  }
  options.transformTag = transformTag
  return options
}

function transformTag (tagData) {
  if (tagData.bgColor && tagData.color) {
    tagData.style = `--tag-bg: ${tagData.bgColor}; --tag-text-color: ${tagData.color};`
  }
}

function suggestionItemTemplate (tagData) {
  const tagStyle = `background-color: ${tagData.bgColor || '#F3F3F3'}; color: ${tagData.color || 'black'}; border-radius: 3px; padding: 0.3em 0.5em; display: inline-block; margin: 0.2em;` // Стиль для "тега"

  return `
        <div ${this.getAttributes(tagData)} 
            class='tagify__dropdown__item ${tagData.class ? tagData.class : ''} d-flex justify-content-between align-items-center' 
            tabindex="0" 
            role="option">
              <div class='flex-grow-1'>
                 <!-- Основной тег и автор в одном блоке -->
                <div class='tag-item' style="${tagStyle}">
                <div>${tagData.value}</div>
                ${tagData.author ? `<div  class="text-muted small">${tagData.author}</div>` : ''}
        </div> 
            </div>
        </div>
    `
}
