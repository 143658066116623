import Popover from 'bootstrap/js/dist/popover'
import Modal from 'bootstrap/js/dist/modal'

import { user } from '../app.js'
import { updateItemInFirebase } from '../server/firebase.js'
import { map } from '../constants.js'
import { showErrorToast } from './toastControl.js'
import { handleLogoutClick } from '../login.js'
import { launchConfetti } from '../confetti.js'

export function setupUserInfoPopover (userName) {
  const userInfoBtn = document.getElementById('userInfoBtn')
  const popBody = createUserInfoBodyElement(userName)
  const userInfoPopover = createUserInfoPopover(userInfoBtn, popBody)
  // поскольку при выходе и входе поповер не создается заново, то надо вручную обновлять его содержимое
  userInfoPopover.setContent({
    '.popover-body': popBody
  })

  const exitBtn = popBody.querySelector('.exit-button')
  exitBtn.addEventListener('click', handleLogoutClick)
}

export function createUserInfoPopover (popoverTrigger, body) {
  // Создание HTML-структуры тела popover
  // const content = createUserInfoBodyElement(userName)
  // Шаблон HTML для popover
  const popTemplateTags = `
            <div class="popover popover-user-info" role="popover">
                <div class="popover-arrow"></div>
                <div class="popover-body p-0"></div>
            </div>
        `
  // Настройки для инициализации popover
  const options = {
    html: true, // Разрешение интерпретации HTML-кода внутри popover
    template: popTemplateTags, // Использование предопределенного шаблона для popover
    content: body, // Добавление содержимого в тело popover
    trigger: 'focus'
  }
  // Создание и возвращение экземпляра popover, привязанного к элементу, вызвавшему событие
  return Popover.getOrCreateInstance(popoverTrigger, options)
}

// Функция для создания содержимого тела popover
export function createUserInfoBodyElement (userName) {
  let htmlContent
  if (userName) {
    // Если имя пользователя определено, выводим приветствие и кнопку выхода
    htmlContent = `
      <div class="d-flex flex-column justify-content-center">
        <div class="m-3 text-center p-2">
          <img src="./img/namaste.svg" alt="Namaste Icon" width="70" height="70">
          <p class="mb-1 mt-1">Намасте<br><strong>${userName}</strong></p>
        </div>
        <hr class="my-1 mb-0 pb-0">
        <div class="justify-content-center d-flex p-1 exit-btn-div">
          <button class="ms-3 me-3 mt-1 mb-1 btn exit-button btn-sm" id="popover-logout-button" type="button">
            Выйти
          </button>
        </div>
      </div>
    `
  } else {
    // Если имя пользователя не определено, выводим кнопку "Указать имя"
    htmlContent = `
      <div class="d-flex flex-column justify-content-center">
        <div class="m-3 text-center p-2">
         <p class="mb-1 mt-1 fs-5">Давайте<br></p>
          <button class="ms-3 me-3 mt-3 mb-1 btn my-secondary-btn" id="popover-set-name-button" type="button">
            знакомиться
          </button>
        </div>
         <hr class="my-1 mb-0 pb-0">
        <div class="justify-content-center d-flex p-1 exit-btn-div">
          <button class="ms-3 me-3 mt-1 mb-1 btn exit-button btn-sm" id="popover-logout-button" type="button">
            Выйти
          </button>
        </div>
      </div>
    `
  }

  // Создаем контейнер и присваиваем ему HTML-содержимое
  const divContainer = document.createElement('div')
  divContainer.classList.add('d-flex') // Добавление отступа снизу
  divContainer.classList.add('flex-column') // Добавление отступа снизу
  divContainer.classList.add('justify-content-center') // Добавление отступа снизу
  divContainer.innerHTML = htmlContent

  // Добавляем обработчик события для кнопки "Указать имя"
  const setNameButton = divContainer.querySelector('#popover-set-name-button')
  if (setNameButton) {
    setNameButton.addEventListener('click', () => {
      // Открытие модального окна для ввода имени
      openSetNameModal()
    })
  }

  return divContainer // Возвращаем первый дочерний элемент, содержащий готовую разметку
}

// Функция для создания и отображения модального окна для ввода имени пользователя
export function openSetNameModal () {
  // HTML для модального окна ввода имени пользователя
  const modalHtml = `
    <div class="modal fade" id="setNameModal" tabindex="-1" aria-labelledby="setNameModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header my-secondaryColor my-bg-primaryColor bg-gradient">
            <h6 class="modal-title" id="setNameModalLabel">Как Вас зовут?</h6>
            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Закрыть"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3 mt-4">
              <input type="text" class="form-control" id="userNameInput" placeholder="Введите Ваше имя">
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn my-primary-btn" data-bs-dismiss="modal">Отмена</button>
            <button type="button" class="btn my-secondary-btn" id="saveUserNameButton">Сохранить</button>
          </div>
        </div>
      </div>
    </div>
  `

  // HTML для второго модального окна приветствия "Намасте"
  const secondModalHtml = `
  <div class="modal fade" id="namasteModal" tabindex="-1" aria-labelledby="namasteModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header my-secondaryColor my-bg-primaryColor bg-gradient">
          <h6 class="modal-title" id="namasteModalLabel">Приветствие</h6>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Закрыть"></button>
        </div>
        <div class="modal-body text-center"> <!-- Выровняем содержимое по центру -->
          <img src="./img/namaste.svg" alt="Намасте" class="img-fluid mb-3" style="max-width: 200px;">
          <p>Намасте, <strong id="userNameDisplay"></strong>!</p>
        </div>
        <div class="modal-footer justify-content-center">
          <button type="button" class="btn my-secondary-btn" data-bs-dismiss="modal">Намасте</button>
        </div>
      </div>
    </div>
  </div>
`

  // Вставляем HTML для первого и второго модального окна в DOM
  document.body.insertAdjacentHTML('beforeend', modalHtml)
  document.body.insertAdjacentHTML('beforeend', secondModalHtml)

  // Инициализация модальных окон
  const setNameModal = new Modal(document.getElementById('setNameModal'))
  const namasteModalElement = document.getElementById('namasteModal')
  const namasteModal = new Modal(namasteModalElement)

  // Добавляем обработчик для запуска анимации конфетти при показе приветственного окна
  namasteModalElement.addEventListener('shown.bs.modal', () => {
    // Запускаем анимацию конфетти
    launchConfetti()
  })

  // Показываем модальное окно для ввода имени
  setNameModal.show()

  // Обработчик для кнопки "Сохранить" в модальном окне
  const saveUserName = async () => {
    const userNameInput = document.getElementById('userNameInput')
    const userNameValue = userNameInput.value
    if (userNameValue) {
      try {
        // Логика сохранения имени пользователя
        console.log(`Имя пользователя сохранено: ${userNameValue}`)
        user.name = userNameValue
        await updateItemInFirebase(user, map.users.usersListName)

        // Скрываем первое модальное окно
        setNameModal.hide()

        // Обновляем popover с новым именем пользователя
        const newBody = createUserInfoBodyElement(userNameValue)
        const popoverTrigger = document.getElementById('userInfoBtn')
        const userInfoPopover = createUserInfoPopover(popoverTrigger, newBody)
        userInfoPopover.setContent({
          '.popover-body': newBody
        })
        const exitBtn = newBody.querySelector('.exit-button')
        exitBtn.addEventListener('click', handleLogoutClick)

        // Показываем второе модальное окно с приветствием "Намасте" и именем пользователя
        document.getElementById('userNameDisplay').textContent = userNameValue
        namasteModal.show()
      } catch (error) {
        console.log(error)
        showErrorToast('Не удалось сохранить имя')
      }
    } else {
      // Устанавливаем более мотивирующий placeholder, если поле пустое
      userNameInput.placeholder = 'Только Брахман вне имени и формы, а Вас как зовут?'
    }
  }

  // Обработчик для кнопки "Сохранить"
  document.getElementById('saveUserNameButton').addEventListener('click', saveUserName)

  // Добавляем обработчик на ввод имени, чтобы сохранить при нажатии на Enter
  document.getElementById('userNameInput').addEventListener('keydown', (event) => {
    if (event.key === 'Enter') {
      saveUserName()
    }
  })
}
// Функция для создания и инициализации popover для подтверждения удаления тега
// в текущей версии не используется (вместо этого появляется модальное окно)
// export function createConfirmPopover (popoverTrigger) {
//   // Создание HTML-структуры тела popover
//   const content = createConfirmBodyElement()
//   // Шаблон HTML для popover
//   const popTemplateTags = `
//             <div class="popover confirmPopover" role="popover">
//                 <div class="popover-arrow"></div>
//                 <div class="popover-inner d-flex pop-title bg-gradient rounded-top">
//                     <div class="col ms-3 mt-2 mb-2">Удалить тег?</div>
//                     </div>
//                 <div class="popover-body"></div>
//             </div>
//         `
//   // Настройки для инициализации popover
//   const options = {
//     html: true, // Разрешение интерпретации HTML-кода внутри popover
//     template: popTemplateTags, // Использование предопределенного шаблона для popover
//     content, // Добавление содержимого в тело popover
//     trigger: 'focus'
//   }
//   // Создание и возвращение экземпляра popover, привязанного к элементу, вызвавшему событие
//   return Popover.getOrCreateInstance(popoverTrigger, options)
// }

// Функция для создания содержимого тела popover
// export function createConfirmBodyElement () {
//   const inputGroup = createDivContainer()
//   const confirmationDiv = document.createElement('div')
//   confirmationDiv.innerHTML = 'Это действие приведет к удалению данного тега во всех записях.'
//   confirmationDiv.classList.add('mb-2') // Добавление отступа снизу
//   inputGroup.appendChild(confirmationDiv) // Добавление текста подтверждения в контейнер
//   inputGroup.appendChild(createConfirmButtonsContainer()) // Добавление кнопок подтверждения и отмены в контейнер

//   return inputGroup // Возврат готового блока содержимого
// }

// function createConfirmButtonsContainer () {
//   // Create a template literal with your HTML structure
//   const buttonsContainerHTML = `
//     <div class="d-flex justify-content-between">
//         <button type="button"
//             id="popover-confirm-delete-button"
//             class="btn mb-1 me-2 mt-0 pe-2 popover-confirm-delete-button">
//             Удалить
//         </button>
//         <button type="button"
//             id="popover-confirm-cancel-button"
//             class="btn mb-1 mt-0 ms-2 ps-2 popover-confirm-cancel-button">
//             Отмена
//         </button>
//     </div>
//     `

//   // Convert the HTML string into DOM elements
//   const tempContainer = document.createElement('div')
//   tempContainer.innerHTML = buttonsContainerHTML.trim()

//   // Return the first child (your buttons container) of the temporary container
//   return tempContainer.firstChild
// }

// Вспомогательная функция для создания контейнера div
function createDivContainer () {
  const divContainer = document.createElement('div')
  divContainer.classList.add('d-grid', 'gap-2') // Добавление классов для стилизации и отступов
  divContainer.style.width = '240px' // Установка фиксированной ширины для контейнера
  return divContainer
}
export const popTemplateForHeader = (title) => {
  return `
            <div class="popover popover-filter" role="popover">
                <div class="popover-arrow"></div>
                <div class="popover-inner d-flex pop-title bg-gradient rounded-top"> 
                    <div class="col ms-3 mt-2 mb-2">${title}</div>
                    <div class="col-auto me-3 mt-2 mb-2">
                       <span id="pop-close">
                       <i class="bi bi-x-lg"></i>
                         </span>
                       </div>
                    </div>
                <div class="popover-body"></div>
            </div>
        `
}

export function createPopInput () {
  const inputGroup = createDivContainer()
  const btnContainer = createButtonsContainer()
  const inputHTML = `
    <input type="text" class="form-control" id="inputTag" placeholder="Поиск...">
      <div class="invalid-feedback">
      Неверный ввод данных     
    </div>
  `
  inputGroup.appendChild(btnContainer)
  inputGroup.innerHTML += inputHTML
  return inputGroup
}

export function createPopSwitch () {
  const switchHTML = `
  <div class="form-check form-switch form-switch-lg">
  <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault">
  </div>
  
  `
  const tempContainer = document.createElement('div')
  tempContainer.innerHTML = switchHTML.trim()
  return tempContainer.firstChild
}

// Создает кнопки "Применить" и "Сбросить" для Popover

export function createButtonsContainer () {
  const buttonsContainerHTML = `
    <div class="d-flex justify-content-between">
      <button type="button" class="btn mb-2 mt-0 p-1 reset-button">
      <span id="spinner-reset-btn" class="spinner-border spinner-border-sm d-none" role="status" aria-hidden="true"></span>
        Сбросить
      </button>
      <button type="button" class="btn mb-2 mt-0 p-1 apply-button">
        <span id="spinner-apply-btn" class="spinner-border spinner-border-sm d-none" role="status" aria-hidden="true"></span>
        Применить
      </button>
    </div>
  `

  const tempContainer = document.createElement('div')
  tempContainer.innerHTML = buttonsContainerHTML.trim()
  return tempContainer.firstChild
}
