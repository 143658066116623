/**
 * Модуль для управления таблицей Tabulator и взаимодействия с данными
 *
 * Этот модуль предоставляет функции для создания, фильтрации и управления таблицей с данными.
 * Включает в себя настройку колонок, формирование пользовательских фильтров, работа с popover элементами
 * для фильтрации по полям, а также управление данными в таблице (добавление, обновление, удаление строк).
 *
 * Основные функции модуля:
 *
 * - **Инициализация таблицы**: Создает и настраивает таблицу с использованием библиотеки Tabulator.
 * - **Форматирование данных**: Включает в себя пользовательские функции форматирования ячеек для отображения тегов, бейджей, дат, времени и т.д.
 * - **Фильтрация и сортировка**: Реализует пользовательские функции фильтрации и сортировки данных на основе различных параметров, включая теги, даты и текстовые поля.
 * - **Управление данными**: Включает функции для загрузки данных, добавления новых записей, обновления существующих строк и удаления строк.
 * - **Взаимодействие с таблицей**: Поддерживает события клика по строкам таблицы для редактирования данных, а также фильтрации с использованием всплывающих окон (popovers).
 * - **Контекстное меню**: Позволяет пользователям выбирать, какие колонки отображать в таблице с помощью контекстного меню.
 *
 *
 */

import { TabulatorFull as Tabulator } from 'tabulator-tables'
import Popover from 'bootstrap/js/dist/popover'
import { map } from '../constants.js'
import { tagifyElements, inputElements, maskElements } from '../app.js'
import { openRightPanel } from './panels.js'

import { popTemplateForHeader, createPopInput, createPopSwitch } from './popoverControl.js'
import { createSearchTagify } from './tagifySearchControl.js'
import { createMaskTime, createMaskDate } from '../mask.js'
import { resetMaskValidationUI, updateValidationState } from './rightPanel.js'
import { initializeAudioPlayer, stopAudio } from '../audioPlayer.js'

// определяется при вычитки из базы
let originalData

// Кэширование часто используемых элементов DOM

const audioPlayerContainer = document.getElementById('audio-player-container')

const alertMsgSpinner = `
        <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        `
// Контекстное меню для выбора колонок
const headerContextMenu = function () {
  const menu = []
  const columns = table.getColumns()
  columns.shift()

  for (const column of columns) {
    const icon = document.createElement('i')
    icon.classList.add('bi')
    icon.classList.add('bi-check2')
    icon.classList.toggle('invisible', !column.isVisible())

    const labelText = document.createElement('span')
    labelText.classList.add('ms-2')
    labelText.textContent = column.getDefinition().title
    const label = document.createElement('span')
    label.appendChild(icon)
    label.appendChild(labelText)

    menu.push({
      label,
      action: function (e) {
        e.stopPropagation()
        column.toggle()
        icon.classList.toggle('invisible', !column.isVisible())
        table.redraw()
      }
    })
  }

  return menu
}

/**
 * Считает количество слов в строке.
 * @param {string} text - Входная строка.
 * @returns {number} - Количество слов.
 */
const countWordsInString = (text) => text.trim().split(/\s+/).length

function highlightFormatter (cell, formatterParams, onRendered) {
  const value = cell.getValue()
  const row = cell.getRow().getData()
  const field = cell.getColumn().getField()

  if (row.longestMatches && row.longestMatches[field]) {
    const [startIndex, endIndex] = row.longestMatches[field]
    const keyword = value.slice(startIndex, endIndex + 1).toLowerCase()
    const text = value.toLowerCase()
    const keywordIndex = text.indexOf(keyword)

    const prevDot = text.lastIndexOf('.', keywordIndex)
    const prevSpace = text.lastIndexOf(' ', keywordIndex)

    let displayedText
    const numWordsToKeyword = countWordsInString(value.slice(prevDot + 1, keywordIndex))
    if (prevDot > prevSpace && numWordsToKeyword <= 3) {
      displayedText = '...' + value.slice(prevDot + 1)
    } else {
      const prevSpace1 = text.lastIndexOf(' ', prevSpace - 1)
      const prevSpace2 = text.lastIndexOf(' ', prevSpace1 - 1)
      if (prevSpace2 > -1) {
        displayedText = '...' + value.slice(prevSpace2 + 1)
      } else {
        displayedText = '...' + value.slice(prevSpace + 1)
      }
    }

    const newKeywordIndex = displayedText.toLowerCase().indexOf(keyword)
    const highlightedValue =
      displayedText.slice(0, newKeywordIndex) +
      '<span class="highlight">' +
      displayedText.slice(newKeywordIndex, newKeywordIndex + keyword.length) +
      '</span>' +
      displayedText.slice(newKeywordIndex + keyword.length)

    return highlightedValue
  }

  return value
}

// Formatter for tooltips with highlights
const tooltipFormatter = function (e, cell, onRendered) {
  const value = cell.getValue()
  const row = cell.getRow().getData()
  const field = cell.getColumn().getField()

  if (row.longestMatches && row.longestMatches[field]) {
    const highlightedValue = highlightText(value, row.longestMatches[field])
    return highlightedValue
  }

  return value
}

// формирует стили для ячеек "Тип", "Статус аудио", "Статус видео"
function badgeFormatter (cell, mapLookupName) {
  const value = cell.getValue()
  if (value) {
    return `<span class="cell-badge" style="hyphens: auto; color: ${value.color}; background-color: ${value.bgColor};">${value.value}</span>`
  } else {
    return ''
  }
}

// формирует html и css для тегов
function tagsFormatter (cell, formatterParams, onRendered, data) {
  const tags = cell.getValue()
  if (!tags || tags.length === 0) {
    return ''
  }
  return tags
    .map(tag => {
      return `<span class="cell-badge" style="color: ${tag.color}; background-color: ${tag.bgColor};">${tag.value}</span>`
    })
    .join(' ')
}

function singleItemFormatter (cell) {
  const item = cell.getValue()
  if (!item) {
    return ''
  }
  return item.value
}

function timeFormatter (cell) {
  const value = cell.getValue()
  return formatTime(value)
}

/**
 * Если время в формате 00:00:00 (так хранится в базе) то отображать в табилце пустую строку,
 *  либо паттерн маски в правой панеле
 * @param {string} str - Строка времени.
 * @returns {string} - Отформатированное время.
 */
function formatTime (str) {
  const pattern = /^00:00:00$/
  return pattern.test(str) ? '' : str
}
// Кастомная функция сортировки
function singleSorter (a, b, aRow, bRow, column, dir, sorterParams) {
  if (a && a.value) {
    a = a.value
  }
  if (b && b.value) {
    b = b.value
  }
  return a.localeCompare(b)
}
// сортирует по первому  тегу. a и b должны быть массивами
function tagSorter (a, b, aRow, bRow, column, dir, sorterParams) {
  function getValue (data) {
    if (!data) return ''
    return data.length > 0 && data[0] && data[0].value ? data[0].value : ''
  }

  const aValue = getValue(a)
  const bValue = getValue(b)

  return aValue.localeCompare(bValue)
}
// Функция для подсветки текста
function highlightText (value, longestMatches) {
  if (longestMatches) {
    const [startIndex, endIndex] = longestMatches
    return value.slice(0, startIndex) +
      '<span class="highlight">' + value.slice(startIndex, endIndex + 1) + '</span>' +
      value.slice(endIndex + 1)
  }
  return value
}

const columns = [
  { title: 'key', field: 'key', visible: false },
  {
    title: 'Дата',
    field: 'date',
    headerContextMenu,
    responsive: 0,
    frozen: true,
    width: 110,
    minWidth: 110,
    headerClick: headerClickPopDate,
    headerFilter: 'input',
    headerFilterFunc: filterDate
  },
  {
    title: 'Тип',
    field: map.type.fieldName,
    headerContextMenu,
    responsive: 0,
    width: 160,
    minWidth: 90,
    formatter: badgeFormatter,
    formatterParams: 'type',
    headerFilter: 'input',
    headerFilterFunc: headFilterSingleTag,
    headerClick: headerClickPopSelect,
    sorter: singleSorter
  },
  {
    title: 'Статус аудио',
    field: map.audioStatus.fieldName,
    headerContextMenu,
    width: 140,
    minWidth: 110,
    formatter: badgeFormatter,
    formatterParams: 'audioStatus',
    headerFilter: 'input',
    headerFilterFunc: headFilterSingleTag,
    headerClick: headerClickPopSelect,
    sorter: singleSorter
  },
  {
    title: 'Статус видео',
    field: map.videoStatus.fieldName,
    headerContextMenu,
    width: 150,
    minWidth: 110,
    formatter: badgeFormatter,
    formatterParams: 'videoStatus',
    headerFilter: 'input',
    headerFilterFunc: headFilterSingleTag,
    headerClick: headerClickPopSelect,
    sorter: singleSorter
  },
  {
    title: 'Обработчик',
    field: map.handler.fieldName,
    headerContextMenu,
    visible: false,
    minWidth: 100,
    headerFilter: 'input',
    formatter: singleItemFormatter,
    headerClick: headerClickPopSelect,
    headerFilterFunc: headFilterSingleTag,
    sorter: singleSorter
  },
  {
    title: 'Место',
    field: map.place.fieldName,
    headerContextMenu,
    visible: false,
    minWidth: 100,
    headerFilter: 'input',
    formatter: singleItemFormatter,
    headerClick: headerClickPopSelect,
    headerFilterFunc: headFilterSingleTag,
    sorter: singleSorter
  },
  {
    title: 'Автор',
    field: map.author.fieldName,
    headerContextMenu,
    visible: false,
    minWidth: 100,
    headerFilter: 'input',
    formatter: singleItemFormatter,
    headerClick: headerClickPopSelect,
    headerFilterFunc: headFilterSingleTag,
    sorter: singleSorter
  },
  {
    title: 'Писание',
    field: map.scriptures.fieldName,
    headerContextMenu,
    width: 100,
    minWidth: 80,
    visible: false,
    headerFilter: 'input',
    formatter: tagsFormatter,
    headerClick: headerClickPopSelect,
    headerFilterFunc: headFilterManyTags,
    sorter: tagSorter
  },
  {
    title: 'Название',
    field: 'title',
    headerContextMenu,
    width: 220,
    minWidth: 100,
    formatter: highlightFormatter,
    tooltip: tooltipFormatter,
    headerFilter: 'input',
    headerClick: headerClickPopInput
  },
  {
    title: 'Комментарии',
    field: 'comment',
    headerContextMenu,
    width: 150,
    minWidth: 110,
    visible: false,
    // formatter: highlightFormatter,
    headerFilter: 'input',
    headerClick: headerClickPopInput
  },
  {
    title: 'Описание',
    field: 'description',
    headerContextMenu,
    responsive: 1,
    width: 250,
    minWidth: 100,
    formatter: highlightFormatter,
    tooltip: tooltipFormatter,
    headerFilter: 'input',
    headerClick: headerClickPopInput,
    visible: true
  },
  {
    title: 'Чтец',
    field: map.reader.fieldName,
    headerContextMenu,
    visible: false,
    minWidth: 100,
    headerFilter: 'input',
    formatter: singleItemFormatter,
    headerClick: headerClickPopSelect,
    headerFilterFunc: headFilterSingleTag,
    sorter: singleSorter
  },
  {
    title: 'Длительность',
    field: 'duration',
    headerContextMenu,
    visible: true,
    width: 100,
    headerFilter: 'time',
    headerClick: headerClickPopTime,
    headerFilterFunc: filterDuration,
    formatter: timeFormatter
  },
  {
    title: 'Ютуб',
    field: 'youtube',
    headerContextMenu,
    visible: true,
    minWidth: 80,
    width: 100,
    headerFilter: 'input',
    headerClick: headerClickPopBool,
    headerFilterFunc: booleanHeaderFilter
  },
  {
    title: 'advayta.org',
    field: 'advayta',
    headerContextMenu,
    visible: true,
    minWidth: 130,
    headerFilter: 'input',
    headerClick: headerClickPopBool,
    headerFilterFunc: booleanHeaderFilter
  },
  {
    title: 'Теги',
    field: map.tags.fieldName,
    headerContextMenu,
    minWidth: 100,
    formatter: tagsFormatter,
    headerFilter: 'input',
    headerClick: headerClickPopSelect,
    headerFilterFunc: headFilterManyTags,
    sorter: tagSorter,
    visible: true
  },
  {
    title: 'Мои метки',
    field: map.marks.fieldName,
    headerContextMenu,
    minWidth: 100,
    formatter: tagsFormatter,
    headerFilter: 'input',
    headerClick: headerClickPopSelect,
    headerFilterFunc: headFilterManyTags,
    sorter: tagSorter,
    visible: true
  }

]

export const table = new Tabulator('#table', {
  index: 'key',
  // reactiveData: true, // enable reactive data
  height: '100%',
  maxHeight: '100%',
  layout: 'fitDataStretch',
  headerSortClickElement: 'icon',
  headerSortElement: '<i class="i-sort bi bi-sort-down"></i>',
  initialSort: [
    { column: 'date', dir: 'desc' } // sort by this first
  ],
  footerElement: '<div class="tabulator-footer" id="footerCalc">Ом Намах Шивайя</div>',
  // responsiveLayout: "collapse",
  // resizableColumnFit: true, //maintain the fit of columns when resizing
  placeholder: 'Ничего не найдено', // display message to user on empty table
  movableColumns: true, // enable user movable columns
  selectableRows: 1,
  history: true, // record table history
  persistence: {
    sort: false, // persist column sorting
    filter: false, // persist filters
    headerFilter: false, // persist header filters
    columns: true // persist columns
  },
  clipboard: true, // enable clipboard functionality
  clipboardCopyRowRange: 'selected', // change default selector to selected
  columns
})

// используется при сбросе фильтров (например, когда строка для поиска пуста и нажимается Enter)
export function loadOriginalDataToTable () {
  table.setData(originalData)
}

export function getOriginalData () {
  return originalData
}

export function getDataFromTable () {
  return table.getData()
}
// Обновляет запись в таблице и также соответствующую строку в полном списке с записями для синхронизации данных
export function updateRowInTable (key, newData) {
  table.updateRow(key, newData)
  updateOriginalDataByKey(key, newData)
}
function updateOriginalDataByKey (key, newData) {
  const index = originalData.findIndex(item => item.key === key)
  if (index !== -1) {
    // Обновляем только те поля, которые есть в newData, остальные остаются без изменений
    originalData[index] = { ...originalData[index], ...newData }
  }
}

export function updateTable (updatedRows) {
  table.updateData(updatedRows)
  updateOriginalData(updatedRows)
}

function updateOriginalData (updatedRows) {
  updatedRows.forEach(newData => {
    const index = originalData.findIndex(item => item.key === newData.key)
    if (index !== -1) {
      originalData[index] = { ...newData }
    }
  })
}

export function addRowInTable (newData) {
  table.addRow(newData, true)
  originalData.push(newData)
}

// вызывается при вычитки данных из БД
export function setOriginalData (data) {
  originalData = data
}

export function deleteRow (key) {
  table.deleteRow(key)
  deleteRowInOriginalData(key)
}

function deleteRowInOriginalData (key) {
  const index = originalData.findIndex(iten => iten.key === key)
  if (index !== -1) {
    originalData.splice(index, 1)
  }
}
export function setDataToTable (data) {
  table.setData(data)
}
/**
 * Используется для поиска по ключевым словам без совпадений.
 * Т.е. ищет только "Не обработано" исключая "обработано" и наоборот
 */
function headFilterSingleTag (headerValue, rowValue, rowData, filterParams) {
  if (!rowValue) {
    return false
  }
  if (!headerValue) {
    return true
  }
  return headerValue.some(header => rowValue.value === header.value)
}

function headFilterManyTags (headerValue, rowValue, rowData, filterParams) {
  if (!rowValue || rowValue.length === 0) {
    return false
  }
  if (!headerValue || headerValue.length === 0) {
    return true
  }
  // Check if any value from headerValue is present in any object within rowValue
  return headerValue.some(header =>
    rowValue.some(row =>
      row.value === header.value
    )
  )
}

function booleanHeaderFilter (headerValue, rowValue, rowData, filterParams) {
  if (headerValue) {
    return rowValue && rowValue.length > 0
  } else return true
}

function filterDate (headerValue, rowValue, rowData, filterParams) {
  if (!headerValue) return true
  // const rowDate = DateTime.fromFormat(rowValue, 'yyyy.MM.dd')
  return rowValue >= headerValue.startDate && rowValue <= headerValue.endDate
}

function filterDuration (headerValue, rowValue, rowData, filterParams) {
  if (!headerValue || /^00:00:00$/.test(headerValue)) {
    return true
  }
  if (!rowValue) return false
  return rowValue < headerValue
}

/// /////////////////////////////////////////// ACTIONS ///////////////////////////////

table.on('rowClick', (e, row) => {
  stopAudio()
  if (!row.isSelected()) { //  эта проверка нужна для того, чтобы при повторном клике по строке выделение не снималось, и можно было бы получить id лекции для меток в tagify
    row.select()
  }
  tagifyElements[map.marks.listName].setDisabled(false) // Включаем поле "пользовательские меки"
  inputElements.key.value = row.getData().key
  console.log(`key: ${row.getData().key}`)
  inputElements.title.value = row.getData().title
  inputElements.comment.value = row.getData().comment
  // Включение подсветки описания
  if (row.getData().longestMatches && row.getData().longestMatches.description) {
    inputElements.description.innerHTML = highlightText(row.getData().description, row.getData().longestMatches.description)
  } else {
    inputElements.description.textContent = row.getData().description
  }

  // делаем лейбл Ютуба кликабельным если поле содержит ссылку
  const url = row.getData().youtube
  inputElements.youtube.value = url
  const label = document.getElementById('youtube-label')
  // Проверка, является ли строка валидной ссылкой
  if (url.match(/^https?:\/\/[^\s$.?#].[^\s]*$/gm)) {
    label.innerHTML = `<a href="${url}" target="_blank">YouTube</a>`
  } else {
    label.textContent = 'YouTube' // Если ссылка не валидна, оставляем простой текст
  }

  // Получаем ссылку на аудиофайл
  const audioSrc = row.getData().advayta
  inputElements.advayta.value = audioSrc

  // Инициализируем аудиоплеер только если есть ссылка
  if (audioSrc) {
    audioPlayerContainer.classList.remove('d-none')
    initializeAudioPlayer(audioSrc, 'audio-player-container')
  } else {
    audioPlayerContainer.classList.add('d-none')
  }

  maskElements.date.iMask.value = row.getData().date
  maskElements.duration.iMask.value = formatTime(row.getData().duration)
  resetMaskValidationUI()

  for (const role of Object.keys(map)) {
    if (!map[role].listName) continue
    const tagify = tagifyElements[role]
    const dataValue = row.getData()[map[role].fieldName]
    const isSingle = map[role].isSingle
    tagify.removeAllTags()
    tagify.DOM.input.textContent = ''
    if (map[role].isRequired) updateValidationState(tagify.DOM.originalInput, true, '', tagify)
    if (dataValue) {
      if (isSingle) {
        tagify.addTags([dataValue])
      } else {
        tagify.addTags(dataValue)
      }
    }
  }
  openRightPanel()
})

// обновляет футер о кол-ве найденных записей после фильтрации
table.on('dataFiltered', function (filters, rows) {
  // filters - array of filters currently applied
  // rows - array of row components that pass the filters
  const totalCount = table.getDataCount() // get total number of rows in table
  let footerStr = 'Всего ' + totalCount + ' записей'
  if (totalCount !== rows.length) {
    footerStr = 'Найдено ' + rows.length + ' из ' + totalCount
  }
  const footerCalcEl = document.getElementById('footerCalc')
  if (footerCalcEl) {
    footerCalcEl.innerHTML = footerStr
  }
})

// Обновляет футер при удалении строки
table.on('rowDeleted', function (row) {
  const totalCount = table.getDataCount() // get total number of rows in table
  const footerStr = 'Всего ' + totalCount + ' записей'
  const footerCalcEl = document.getElementById('footerCalc')
  if (footerCalcEl) {
    footerCalcEl.innerHTML = footerStr
  }
})

/// ///////////////////////////////////////////////// Popover /////////////////////////////////////////////////

let popover,
  previousColumnName,
  mask,
  tagify

let currentPopoverShownListener = null

function headerClickPopDate (e, column) {
  headerClickPopoverMask(e, column, createMaskDate, mask => mask.getDateRangeFromMask())
}

function headerClickPopTime (e, column) {
  headerClickPopoverMask(e, column, createMaskTime, mask => mask.getTimeStr())
}

/// ////////////// MASK /////////////////////////////////////
function headerClickPopoverMask (e, column, createMaskFunction, getDataFromMask) {
  const field = column.getField() // имя колонки (поля в базе)
  if (!shouldHandleClick(e, field)) return

  const popBody = createPopInput()
  const inputField = popBody.querySelector('input')
  const titleEl = getTitleEl(column)
  const currentColumnTitle = titleEl.textContent

  if (mask) {
    mask.iMask.destroy()
  }
  mask = createMaskFunction(inputField)
  mask.iMask.value = mask ? currentColumnTitle : ''

  addEnterKeyListener(inputField, inputApplyBtnClick)

  function inputApplyBtnClick () {
    const data = getDataFromMask(mask)
    updateValidationState(data, 'Неверный формат даты')
    if (!data) return
    popover.hide()
    tableAlertAndUpdate(() => updateColumnHeader(titleEl, column, mask.iMask.value, data))
  }

  function inputResetBtnClick () {
    mask.iMask.value = ''
    updateValidationState(true)
    popover.hide()
    tableAlertAndUpdate(() => updateColumnHeader(titleEl, column, '', ''))
  }

  function updateValidationState (isValid, message = '') {
    const feedbackElement = popBody.querySelector('.invalid-feedback')
    inputField.classList.toggle('is-invalid', !isValid)
    feedbackElement.textContent = isValid ? '' : message
  }

  setupPopoverButtonListeners(popBody, inputApplyBtnClick, inputResetBtnClick)
  initializeHeaderPopover(e, column, popBody, inputField)
}

/// //////////////////////// BOOL /////////////////////////////////////////

function headerClickPopBool (e, column) {
  const field = column.getField() // имя колонки (поля в базе)
  if (!shouldHandleClick(e, field)) return

  const popBody = createPopSwitch()

  const titleEl = getTitleEl(column)

  const switchElement = popBody.querySelector('input')
  const filterValue = column.getHeaderFilterValue()
  switchElement.checked = filterValue

  switchElement.addEventListener('change', () => {
    const switchState = switchElement.checked
    const colomnTitle = switchState ? column.getDefinition().title : false
    popover.hide()
    tableAlertAndUpdate(() => updateColumnHeader(titleEl, column, colomnTitle, switchState))
  })

  initializeHeaderPopover(e, column, popBody, null)
}

/// ///////// INPUT ////////////////////////////////////////////

function headerClickPopInput (e, column) {
  const field = column.getField() // имя колонки (поля в базе)
  if (!shouldHandleClick(e, field)) return

  const popBody = createPopInput()
  const inputField = popBody.querySelector('input')
  const titleEl = getTitleEl(column)
  const headerFilterValue = column.getHeaderFilterValue()

  if (headerFilterValue) inputField.value = headerFilterValue

  addEnterKeyListener(inputField, inputApplyBtnClick)

  function inputApplyBtnClick () {
    popover.hide()
    const inputValue = inputField.value
    const title = inputValue.length > 0 ? inputValue : false
    tableAlertAndUpdate(() => updateColumnHeader(titleEl, column, title, inputValue))
  }

  function inputResetBtnClick () {
    popover.hide()
    inputField.value = ''
    tableAlertAndUpdate(() => updateColumnHeader(titleEl, column, '', ''))
  }
  setupPopoverButtonListeners(popBody, inputApplyBtnClick, inputResetBtnClick)
  initializeHeaderPopover(e, column, popBody, inputField)
}

/// //////////////// SELECT ///////////////////////////////////////////////////////
function headerClickPopSelect (e, column) {
  const field = column.getField() // имя колонки (поля в базе)
  if (!shouldHandleClick(e, field)) return

  const popBody = createPopInput() // внутренняя разметка popover'а для выпадающего списка
  const inputField = popBody.querySelector('input')
  const titleEl = getTitleEl(column)
  const headerFilterValue = column.getHeaderFilterValue()

  if (tagify) tagify.destroy()

  const whitelist = tagifyElements[field].whitelist // значения для выпадающего списка
  tagify = createSearchTagify(whitelist, inputField)
  tagify.addTags(headerFilterValue)

  function selectApplyBtnClick () {
    popover.hide()
    const tagifyCleanValues = tagify.getCleanValue()
    const filterValue = tagifyCleanValues.length > 0 ? tagifyCleanValues : ''
    const toStringValue = tagifyCleanValues.map(obj => obj.value).join(',')
    const title = toStringValue.length > 0 ? toStringValue : false
    tableAlertAndUpdate(() => updateColumnHeader(titleEl, column, title, filterValue))
  }

  function selectResetBtnClick () {
    popover.hide()
    tagify.removeAllTags()
    tableAlertAndUpdate(() => updateColumnHeader(titleEl, column, '', ''))
  }

  setupPopoverButtonListeners(popBody, selectApplyBtnClick, selectResetBtnClick)
  initializeHeaderPopover(e, column, popBody, tagify.DOM.input)
}

function initializeHeaderPopover (e, column, popoverBody, inputField) {
  const columnHeader = setColumnHeaderPopAttr(column.getElement())
  const popoverTemplate = popTemplateForHeader(column.getDefinition().title)
  const columnName = column.getField()
  const popoverOptions = {
    html: true,
    template: popoverTemplate,
    content: popoverBody
  }

  if (popover) {
    const previousHeader = column.getTable().getColumn(previousColumnName).getElement()
    previousHeader.removeEventListener('shown.bs.popover', currentPopoverShownListener)
    popover.dispose()
  } else {
    document.addEventListener('click', initDocumentClickListener)
  }

  popover = new Popover(columnHeader, popoverOptions)
  currentPopoverShownListener = createPopoverShownListener(inputField)
  columnHeader.addEventListener('shown.bs.popover', currentPopoverShownListener)

  previousColumnName = columnName

  popover.toggle()
}

export function tableAlertAndUpdate (callback) {
  table.alert(alertMsgSpinner)
  setTimeout(() => {
    callback()
    table.clearAlert()
  }, 100)
}

export function tableAlert (callback) {
  table.alert(alertMsgSpinner)
  callback()
  table.clearAlert()
}

function updateColumnHeader (titleEl, column, value, filterValue) {
  if (value) {
    titleEl.textContent = `*${value}`
    titleEl.style.color = 'red'
  } else {
    titleEl.textContent = column.getDefinition().title
    titleEl.style.color = ''
  }
  column.setHeaderFilterValue(filterValue)
}

function getTitleEl (column) {
  const headerEl = column.getElement()
  return headerEl.querySelector('.tabulator-col-title')
}

function addEnterKeyListener (inputField, actionFunction) {
  inputField.addEventListener('keydown', (e) => {
    if (e.key === 'Enter') {
      actionFunction()
    }
  })
}

function shouldHandleClick (e, field) {
  if (isSortIconClick(e.srcElement)) return false
  if (popover && previousColumnName === field) {
    popover.toggle()
    return false
  }
  return true
}

function createPopoverShownListener (inputEl) {
  return function (event) {
    if (inputEl) {
      inputEl.focus()
    }

    document.getElementById('pop-close').addEventListener('click', () => {
      popover.hide()
    })
  }
}

function isSortIconClick (element) {
  return element.className.includes('i-sort')
}

function setColumnHeaderPopAttr (columnHeader) {
  columnHeader.setAttribute('data-bs-html', true)
  columnHeader.setAttribute('data-bs-placement', 'bottom')
  columnHeader.setAttribute('data-bs-trigger', 'manual')
  return columnHeader
}

function setupPopoverButtonListeners (popBody, applyBtnClick, resetBtnClick) {
  const popoverButtons = getPopoverButtons(popBody)
  popoverButtons.applyButton.onclick = applyBtnClick
  popoverButtons.resetButton.onclick = resetBtnClick
}

function getPopoverButtons (popBody) {
  const applyButton = popBody.querySelector('.apply-button')
  const resetButton = popBody.querySelector('.reset-button')
  return { applyButton, resetButton }
}

function initDocumentClickListener (e) {
  const popoverElement = document.querySelector('.popover-filter')
  if (!popoverElement ||
    (!popoverElement.contains(e.target) &&
    !e.target.classList.contains('tagify__tag__removeBtn'))) {
    if (popover) {
      popover.hide()
    }
  }
}

/// /////////////////////////////////////////////////////////// end popover //////////////////////////////////////////////////////////////////////////////
