// Сердце всего приложения

import { create } from 'canvas-confetti'

export function launchConfetti () {
  // Создаем экземпляр конфетти с более высоким z-index
  const myConfetti = create(null, {
    resize: true,
    useWorker: true
  })

  // Запускаем конфетти с заданными параметрами
  myConfetti({
    particleCount: 150,
    startVelocity: 30,
    spread: 360,
    origin: { y: 0.5 },
    zIndex: 9999 // Обеспечивает отображение конфетти поверх всех элементов
  })
}
