// для маски ввода даты
export const DATE_FORMAT = 'yyyy.MM.dd'

// Набор колонок для поиска
export const filterColumns = Object.freeze({
  DATE: 'date',
  TITLE: 'title',
  DESCRIPTION: 'description'
})

// Central configuration for Tagify elements and related Firebase paths
export const map = Object.freeze({
  ENTITIES: 'entities',
  users: {
    usersListName: 'users',
    props: {
      name: 'name',
      key: 'key',
      email: 'email',
      role: 'role',
      marks: 'marks' // снизу следует описание этого свойства
    }
  },
  marks: { // пользователские мекти (теги)
    elementId: 'marks',
    fieldName: 'marks', // как это поле называется у лекции в таблице
    props: ['key', 'value', 'color', 'bgColor', 'entities'],
    isSingle: false,
    isPainted: true,
    listName: 'marks'
  },

  roles: {
    editor: 'editor', reader: 'reader'
  },
  type: {
    elementId: 'type',
    listName: 'types',
    fieldName: 'type',
    props: ['key', 'value', 'color', 'bgColor'],
    isSingle: true, // множественный выбор либо единичный
    isPainted: true, // разукрашивать или нет
    isRequired: true
  },
  audioStatus: {
    elementId: 'audio-status',
    listName: 'audioStatuses',
    fieldName: 'audioStatus',
    props: ['key', 'value', 'color', 'bgColor'],
    isSingle: true,
    isPainted: true,
    isRequired: true
  },
  videoStatus: {
    elementId: 'video-status',
    listName: 'videoStatuses',
    fieldName: 'videoStatus',
    props: ['key', 'value', 'color', 'bgColor'],
    isSingle: true,
    isPainted: true,
    isRequired: true
  },
  author: {
    elementId: 'author',
    listName: 'sannyasis',
    fieldName: 'author',
    props: ['key', 'value'],
    isSingle: true,
    isRequired: true
  },
  reader: {
    elementId: 'reader',
    listName: 'sannyasis',
    fieldName: 'reader',
    props: ['key', 'value'],
    isSingle: true
  },
  handler: {
    elementId: 'handler',
    listName: 'handlers',
    fieldName: 'handler',
    props: ['key', 'value'],
    isSingle: true
  },
  scriptures: {
    elementId: 'scriptures',
    listName: 'scriptures',
    fieldName: 'scriptures',
    props: ['key', 'value', 'author'],
    isSingle: false
  },
  place: {
    elementId: 'place',
    listName: 'places',
    fieldName: 'place',
    props: ['key', 'value'],
    isSingle: true
  },

  tags: {
    elementId: 'tags',
    listName: 'tags',
    fieldName: 'tags',
    props: ['key', 'value', 'color', 'bgColor'],
    isSingle: false,
    isPainted: true
  }

})
