import { map } from './constants.js'
import { table, updateRowInTable } from './uiControl/tabulatorControl.js'

/**
 * Возвращает путь для сохранения меток в Firebase.
 *
 * @param {boolean} isUserMark - Является ли элемент пользовательской меткой.
 * @param {Object} uid - Объект пользователя, содержащий UID.
 * @param {Object} property - Свойства элемента из карты (map).
 * @returns {string} - Путь для сохранения данных в Firebase.
 */
export const getFirebasePathToMark = (uid) =>
  `${map.users.usersListName}/${uid}/${map.marks.listName}`

/**
 * Проверяет, является ли элемент пользовательской меткой.
 * @param {Object} elementId - id элемента.
 * @returns {boolean} - Возвращает true, если элемент является пользовательской меткой.
 */
export const isUserMarkElement = (elementId) => elementId === map.marks.elementId

/**
 * Обновляет данные меток в записи и таблице.
 * @param {Object} record - Запись из таблицы, которую нужно обновить.
 * @param {Object} cleanData - Данные метки (mark), которые нужно добавить или обновить.
 */

export function updateRecordMarksInTable (record, cleanData) {
  // Создаем глубокую копию текущего массива меток, чтобы избежать изменения оригинального массива
  const marks = record.marks ? [...record.marks] : []

  // Добавляем или обновляем метку в marks записи
  const markIndex = record.marks.findIndex(mark => mark.key === cleanData.key)
  if (markIndex === -1) {
    marks.push(cleanData)
  } else {
    marks[markIndex] = cleanData
  }
  updateRowInTable(record.key, { marks })
}

export function updateUserMarks (user) {
  const userMarks = user.marks || {}

  // Получаем все строки из таблицы Tabulator
  const allRows = table.getRows()

  // Проходим по каждой записи в таблице
  allRows.forEach(row => {
    const record = row.getData()
    const recordKey = record.key

    // Инициализируем массив для обновленных меток для этой записи
    const updatedMarks = []

    Object.values(userMarks).forEach(mark => {
      if (mark.entities && mark.entities[recordKey]) {
        updatedMarks.push(mark)
      }
    })
    // Обновляем поле marks записи с обновленным массивом меток
    updateRowInTable(recordKey, { marks: updatedMarks })
  })
}
