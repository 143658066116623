// app entrance
/**
 * Этот файл является точкой входа для веб-приложения.
 * Он отвечает за инициализацию приложения после загрузки DOM, обработку аутентификации пользователя,
 * загрузку данных из Firebase и настройку UI-компонентов.
 *
 * Основные функции файла:
 * - Отслеживание состояния аутентификации пользователя и управление доступом.
 * - Загрузка и обработка данных из Firebase, включая мэппинг сущностей со списками.
 * - Инициализация UI-компонентов, таких как маски ввода и элементы Tagify.
 * - Настройка слушателей событий для взаимодействия с пользователем (поиск, добавление записей, выход из аккаунта, экспорт данных).
 *
 * Этот файл экспортирует переменные и объекты, которые используются в других модулях приложения,
 * обеспечивая связь между различными частями кода.
 */

import '../scss/styles.scss'
import Modal from 'bootstrap/js/dist/modal'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { map } from './constants.js'
import { setOriginalData, loadOriginalDataToTable, tableAlertAndUpdate, table } from './uiControl/tabulatorControl.js'
import { openRightPanel } from './uiControl/panels.js'
import { createMaskDate, createMaskTime } from './mask.js'
import { readFromFirebase, getUser } from './server/firebase.js'
import { login, setPermissions } from './login.js'
import { searchData } from './search.js'
import { createSelectTagify } from './uiControl/tagifySelectControl.js'
import { resetAllFields } from './uiControl/rightPanel.js'
import { exportToExcel } from './excel.js'
import { stopAudio } from './audioPlayer.js'
import { setupUserInfoPopover } from './uiControl/popoverControl.js'
import { updateUserMarks } from './userMarksUtils.js'

// Объявление переменных
let entities // Хранит данные сущностей из Firebase
export let user // Хранит информацию о текущем пользователе
export const tagifyElements = {} // Содержит экземпляры Tagify для различных полей
export const maskElements = {} // Содержит маски для полей даты и времени
export const inputElements = {
  key: document.getElementById('key'),
  title: document.getElementById('title'),
  comment: document.getElementById('comments'),
  description: document.getElementById('description'),
  youtube: document.getElementById('youtube-link'),
  advayta: document.getElementById('website-link')
}
// Метки пользователя
export const userMarksElement = {}

// Событие, которое срабатывает после загрузки DOM
document.addEventListener('DOMContentLoaded', () => {
  const auth = getAuth() // Получаем экземпляр Firebase Auth
  const loadingElements = initLoadingElements() // Инициализируем элементы загрузки

  // Отслеживаем изменение состояния аутентификации пользователя
  onAuthStateChanged(auth, async (userAuth) => {
    if (userAuth) {
      // Пользователь аутентифицирован
      console.log('Пользователь аутентифицирован:', userAuth)
      user = await getUser(userAuth) // Получаем информацию о пользователе из Firebase
      setupUserInfoPopover(user.name ? user.name : '')
      if (!entities) {
        // Если данные еще не загружены, инициализируем приложение
        await init(loadingElements)
      } else {
        updateUserMarks(user)
        // Данные уже загружены, скрываем индикатор загрузки и устанавливаем разрешения
        hideLoading(loadingElements)
        setPermissions(user.role)
      }
    } else {
      // Пользователь не аутентифицирован, показываем форму входа
      setupLoginForm(loadingElements)
      loadingElements.loginModal.show()
    }
  })

  setupEventListeners() // Настраиваем слушатели событий
})

/**
 * Инициализирует приложение: загружает данные и настраивает UI-компоненты.
 * @param {Object} loadingElements - Элементы, связанные с отображением загрузки.
 */
async function init (loadingElements) {
  try {
    const data = await fetchDataFromFirebase() // Получаем данные из Firebase
    entities = processEntitiesData(data) // Обрабатываем полученные данные
    setOriginalData(entities) // Устанавливаем исходные данные для таблицы
    loadOriginalDataToTable() // Загружаем данные в таблицу
    initializeUIComponents(data) // Инициализируем UI-компоненты
    setPermissions(user.role) // Устанавливаем разрешения на основе роли пользователя
  } catch (error) {
    console.error('Ошибка инициализации:', error)
    showError('Произошла ошибка во время инициализации.')
  } finally {
    hideLoading(loadingElements) // Скрываем индикатор загрузки в любом случае
  }
}

/**
 * Получает данные из Firebase.
 * @returns {Object} Данные, полученные из Firebase.
 */
async function fetchDataFromFirebase () {
  return await readFromFirebase('/')
}

/**
 * Обрабатывает данные сущностей, связывая их с соответствующими списками.
 * @param {Object} data - Исходные данные из Firebase.
 * @returns {Array} Массив обработанных сущностей.
 */
function processEntitiesData (data) {
  const rawEntities = data.entities || {}
  const listsFromFB = extractListsFromData(data) // Извлекаем списки для мэппинга
  mapEntitiesWithLists(rawEntities, listsFromFB) // Связываем сущности со списками
  return Object.values(rawEntities) // Возвращаем массив сущностей
}

/**
 * Извлекает списки из данных для последующего мэппинга.
 * @param {Object} data - Исходные данные из Firebase.
 * @returns {Object} Объект, содержащий списки из Firebase.
 */
function extractListsFromData (data) {
  const lists = {}
  for (const key of Object.keys(map)) {
    const property = map[key]

    // Проверка наличия списка listName
    if (property.listName && !lists[property.listName]) {
      // Если listName соответствует меткам пользователя, используем динамический путь
      if (property.listName === map.marks.listName) {
        // Проверка наличия меток для пользователя
        lists[property.listName] = data.users?.[user.key]?.marks ? Object.values(data.users[user.key].marks) : []
      } else {
        // Обычная обработка для других списков
        lists[property.listName] = data[property.listName] ? Object.values(data[property.listName]) : []
      }
    }
  }
  return lists
}

/**
 * Связывает сущности с соответствующими элементами из списков на основе конфигурации в объекте 'map'.
 *
 * @param {Object} entities - Объект сущностей, где ключи - это идентификаторы сущностей, а значения - сами сущности.
 * @param {Object} lists - Объект списков, где ключи - это имена списков из 'listName' в 'map', а значения - массивы элементов списка.
 */
/**
 * Связывает сущности с соответствующими элементами из списков на основе конфигурации в объекте 'map',
 * а также добавляет к каждой сущности значения из меток ('marks'), если таковые существуют.
 *
 * @param {Object} entities - Объект сущностей, где ключи - это идентификаторы сущностей, а значения - сами сущности.
 * @param {Object} lists - Объект списков, где ключи - это имена списков из 'listName' в 'map', а значения - массивы элементов списка.
 */
function mapEntitiesWithLists (entities, lists) {
  // Проходим по каждой сущности в 'entities'
  for (const [key, entity] of Object.entries(entities)) {
    // Добавляем свойство 'key' в каждую сущность для хранения её уникального идентификатора
    entity.key = key

    // Проходим по каждой роли из объекта 'map'
    for (const property of Object.values(map)) {
      // Проверяем, определено ли 'listName' для текущей роли, если нет — пропускаем
      if (!property.listName) continue

      // Проверка на наличие поля marks (специальная обработка для меток)
      if (property.fieldName === map.marks.fieldName) {
        // Ищем метки, связанные с данной сущностью (по ключу)
        entity[property.fieldName] = lists[property.listName].filter(mark => mark.entities && mark.entities[key])
        continue // Переход к следующей роли, т.к. marks обрабатывается отдельно
      }

      // Проверяем, содержит ли текущая сущность поле, соответствующее 'fieldName' из текущей роли
      if (property.fieldName in entity) {
        const itemKey = entity[property.fieldName]

        // Проверяем, является ли поле одиночным или множественным
        entity[property.fieldName] = property.isSingle
          ? lists[property.listName].find((item) => item.key === itemKey) // Одиночное значение
          : Object.keys(itemKey).map((key) => lists[property.listName].find((item) => item.key === key)) // Множественное
      } else if (!property.isSingle) {
        // Инициализируем пустым массивом, если поле отсутствует и не одиночное (чтобы тэги были определены)
        entity[property.fieldName] = []
      }
    }
  }
}

/**
 * Инициализирует UI-компоненты, такие как Tagify и маски ввода.
 * @param {Object} data - Данные, используемые для инициализации компонентов.
 */
function initializeUIComponents (data) {
  // Инициализируем Tagify для каждого соответствующего поля
  for (const roleKey of Object.keys(map)) {
    const property = map[roleKey]
    if (property.listName) {
      // Определяем, какие данные использовать для заполнения тегов (marks или данные из property.listName)
      // Если listName совпадает с map.marks.listName, используем метки пользователя (user.marks)
      const listData = property.listName === map.marks.listName
        ? (user.marks ? Object.values(user.marks) : []) // Используем метки пользователя
        : (data[property.listName] ? Object.values(data[property.listName]) : [])
      tagifyElements[roleKey] = createSelectTagify(listData, property)
    }
  }

  // Инициализируем маски для полей даты и продолжительности
  const dateElement = document.getElementById('date')
  const durationElement = document.getElementById('duration')
  maskElements.date = createMaskDate(dateElement)
  maskElements.duration = createMaskTime(durationElement)
}

/**
 * Настраивает слушатели событий для элементов интерфейса.
 */
function setupEventListeners () {
  // Слушатель для поиска
  const searchControl = document.getElementById('inputSearch')
  searchControl.focus()
  searchControl.addEventListener('keypress', searchData)
  searchControl.addEventListener('search', handleSearchClear)

  // Слушатель для кнопки добавления новой записи
  document.getElementById('addBtn').addEventListener('click', handleAddButtonClick)

  // Слушатель для кнопки экспорта в Excel
  document.getElementById('exportBtn').addEventListener('click', exportToExcel)
}

/**
 * Обработчик события очистки поля поиска.
 */
function handleSearchClear () {
  tableAlertAndUpdate(loadOriginalDataToTable)
}

/**
 * Обработчик нажатия на кнопку добавления новой записи.
 */
function handleAddButtonClick () {
  stopAudio() // Останавливаем аудио при добавлении новой записи
  const audioPlayerContainer = document.getElementById('audio-player-container')
  audioPlayerContainer.classList.add('d-none') // Скрываем аудиоплеер
  table.deselectRow() // deselect all rows
  tagifyElements[map.marks.listName].setDisabled(true)
  resetAllFields() // Сбрасываем все поля ввода
  openRightPanel() // Открываем правую панель для ввода новой записи
}

/**
 * Инициализирует элементы, связанные с отображением загрузки.
 * @returns {Object} Элементы загрузки.
 */
function initLoadingElements () {
  const loadingGif = document.getElementById('loading') // Гифка загрузки
  const modalSignIn = document.getElementById('modalSignin') // Модальное окно входа
  const loginBtn = document.getElementById('loginBtn') // Кнопка входа
  const spinnerEl = loginBtn.querySelector('span.spinner-border') // Спиннер на кнопке входа
  const loginModal = Modal.getOrCreateInstance(modalSignIn) // Экземпляр модального окна Bootstrap

  return {
    loadingGif,
    loginModal,
    loginBtn,
    spinnerEl
  }
}

/**
 * Отображает индикатор загрузки и отключает элементы интерфейса.
 * @param {Object} loadingElements - Элементы загрузки.
 */
function showLoading (loadingElements) {
  loadingElements.spinnerEl.classList.remove('d-none') // Показываем спиннер
  loadingElements.loginBtn.setAttribute('disabled', '') // Отключаем кнопку входа
}

/**
 * Скрывает индикатор загрузки и включает элементы интерфейса.
 * @param {Object} loadingElements - Элементы загрузки.
 */
function hideLoading (loadingElements) {
  loadingElements.loginModal.hide() // Скрываем модальное окно входа
  loadingElements.spinnerEl.classList.add('d-none') // Скрываем спиннер
  loadingElements.loginBtn.removeAttribute('disabled') // Включаем кнопку входа
  loadingElements.loadingGif.classList.add('d-none') // Скрываем гифку загрузки
}

/**
 * Настраивает форму входа и ее слушатели.
 * @param {Object} loadingElements - Элементы загрузки.
 */
function setupLoginForm (loadingElements) {
  const signupForm = document.querySelector('#loginForm')
  signupForm.addEventListener('submit', async (e) => {
    e.preventDefault() // Предотвращаем перезагрузку страницы
    showLoading(loadingElements) // Показываем индикатор загрузки
    await login(e, signupForm) // Выполняем вход
  })

  signupForm.querySelector('#loginMail').value = '' // Очищаем поле email
  signupForm.querySelector('#loginPassword').value = '' // Очищаем поле пароля
}

/**
 * Отображает сообщение об ошибке пользователю.
 * @param {string} message - Текст сообщения об ошибке.
 */
function showError (message) {
  // Реализуйте логику отображения сообщения пользователю
  alert(message) // Пример реализации через alert
}
