/**
 * FirebaseModule
 *
 * Модуль для работы с Firebase, включающий функции для аутентификации пользователей,
 * взаимодействия с Firebase Realtime Database, а также управление данными пользователей.
 *
 * Краткое описание основных функций:
 *
 *  readFromFirebase: Читает данные из базы данных по заданному пути.
 *  addItemToFirebase: Добавляет новый элемент в базу данных по указанному пути.
 *  updateItemInFirebase: Обновляет существующий элемент в базе данных.
 *  removeItemFromFirebase: Удаляет элемент из базы данных по указанному пути.
 *  updateFromAllEntities: Обновляет ссылки на элемент во всех сущностях базы данных.
 *  signIn: Выполняет вход пользователя с помощью email и пароля.
 *  signOutUser: Выполняет выход текущего пользователя из системы.
 *  getUser: Получает данные пользователя из базы данных или создает новую запись с ролью по умолчанию.
 */

import { initializeApp } from 'firebase/app'
import { getDatabase, ref, child, get, update, remove, push, set } from 'firebase/database'
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { map } from '../constants'

const firebaseConfig = {
  apiKey: 'AIzaSyBGGB0g09w3VMWFojvb8RnsSEgNOqKSx0k',
  authDomain: 'gpt4-akasha1-2023-04-16.firebaseapp.com',
  databaseURL: 'https://gpt4-akasha1-2023-04-16-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'gpt4-akasha1-2023-04-16',
  storageBucket: 'gpt4-akasha1-2023-04-16.appspot.com',
  messagingSenderId: '487770847101',
  appId: '1:487770847101:web:de6b559f45c6f8ab867e14',
  measurementId: 'G-4Z71KBLZCY'
}

/**
 * Инициализация Firebase
 *
 * Инициализирует Firebase приложение с использованием предоставленных конфигурационных параметров.
 */
const app = initializeApp(firebaseConfig)

/**
 * Ссылка на Firebase Realtime Database
 *
 * Инициализирует Firebase Realtime Database и возвращает ссылку на базу данных.
 */
export const database = getDatabase(app)

/**
 * Ссылка на корневой узел Firebase Realtime Database
 *
 * Создает ссылку на корневой узел базы данных, откуда можно получать доступ к данным.
 */
const dbRef = ref(database)

/**
 * Аутентификация Firebase
 *
 * Инициализирует сервис аутентификации Firebase для выполнения операций по входу и выходу пользователей.
 */
const auth = getAuth()

/**
 * Чтение данных из Firebase Realtime Database
 *
 * @param {string} path - Путь к данным в Firebase Realtime Database.
 * @returns {Promise<any|null>} - Возвращает промис, который разрешается данными из базы данных или null, если данных нет.
 */
export async function readFromFirebase (path) {
  try {
    // Получаем снимок (snapshot) данных из указанного пути в базе данных
    const snapshot = await get(child(dbRef, path))

    // Проверяем, существуют ли данные по указанному пути
    if (snapshot.exists()) {
      // Возвращаем значение данных из снимка
      return snapshot.val()
    } else {
      // Если данных по указанному пути нет, выводим сообщение об отсутствии данных
      console.log('Данные отсутствуют')
      return null
    }
  } catch (error) {
    // В случае ошибки выводим сообщение об ошибке в консоль
    console.error(error)
  }
}

/**
 * Добавление нового элемента в Firebase Realtime Database
 *
 * @param {Object} item - Объект, представляющий элемент, который нужно добавить.
 * @param {string} path - Путь в Firebase Realtime Database, куда будет добавлен элемент.
 * @returns {Promise<string>} - Возвращает промис, который разрешается с ключом добавленного элемента.
 */
export async function addItemToFirebase (item, path) {
  try {
    const newItemRef = push(ref(database, path))
    const key = newItemRef.key
    await set(newItemRef, { key, ...item })
    return key // Returns the generated key
  } catch (error) {
    console.error(`Ошибка при сохранении ${item} в Firebase: "${path}":`, error)
    throw error
  }
}

/**
 * Обновление элемента в Firebase Realtime Database
 *
 * @param {Object} itemData - Данные элемента, который нужно обновить.
 * @param {string} path - Путь в Firebase Realtime Database, где находится элемент.
 * @returns {Promise<string>} - Возвращает промис с сообщением об успешном обновлении элемента.
 */
export async function updateItemInFirebase (itemData, path) {
  try {
    const updates = {}
    updates[`${path}/${itemData.key}`] = itemData
    await update(dbRef, updates)
    return `Обновление успешно для "${itemData.value}" с ключом "${itemData.key}" по пути "${path}"`
  } catch (error) {
    console.error(`Ошибка при обновлении  "${itemData.value}" по пути "${path}":`, error)
    throw error
  }
}

/**
 * Удаление элемента из Firebase Realtime Database
 *
 * @param {string} itemKey - Ключ элемента, который нужно удалить.
 * @param {string} path - Путь в Firebase Realtime Database, где находится элемент.
 * @returns {Promise<string>} - Возвращает промис с сообщением об успешном удалении элемента.
 */
export async function removeItemFromFirebase (itemKey, path) {
  try {
    await remove(child(dbRef, `${path}/${itemKey}`))
    return `Запись с ключем "${itemKey}" удалена по пути "${path}"`
  } catch (error) {
    console.error(`Ошибка удаления записи по пути "${path}":`, error)
    throw error
  }
}

/**
 * Обновление ключей элементов из списков во всех лекциях в Firebase Realtime Database
 *
 * @param {string} key - Старый ключ элемента.
 * @param {string} newKey - Новый ключ элемента.
 * @param {Array<string>} firebaseTypes - Массив типов списков в Firebase (например, ['handler', 'reader']).
 * @param {boolean} isSingle - Флаг, указывающий, является ли элемент единственным или множественным.
 * @returns {Promise<string>} - Возвращает промис с сообщением об успешном обновлении ключей.
 */
export async function updateFromAllEntities (key, newKey, firebaseTypes, isSingle) {
  try {
    const entitiesData = await readFromFirebase(map.ENTITIES)
    if (!entitiesData) return 'No data found to update.'

    const updates = {}
    Object.entries(entitiesData).forEach(([entityKey, entity]) => {
      firebaseTypes.forEach(type => {
        if (!(type in entity)) return

        if (isSingle && entity[type] === key) {
          updates[`${map.ENTITIES}/${entityKey}/${type}`] = newKey
        } else if (entity[type] && entity[type][key]) {
          updates[`${map.ENTITIES}/${entityKey}/${type}/${key}`] = null
          if (newKey) updates[`${map.ENTITIES}/${entityKey}/${type}/${newKey}`] = newKey
        }
      })
    })

    if (Object.keys(updates).length > 0) {
      await update(dbRef, updates)
      return `${Object.keys(updates).length}`
    } else {
      return 'No data found to update.'
    }
  } catch (error) {
    console.error('Error updating entities:', error)
    throw error
  }
}

/**
 * Чтение пользователей из Firebase Realtime Database
 *
 * @returns {Promise<any>} - Возвращает промис, который разрешается с данными пользователей.
 */
export async function readUsersFromFB () {
  try {
    return await readFromFirebase('users')
  } catch (error) {
    console.error('Ошибка при чтении списка пользователей', error)
    throw error
  }
}

/**
 * Вход пользователя с использованием Firebase Authentication
 *
 * @param {string} email - Email пользователя.
 * @param {string} password - Пароль пользователя.
 * @returns {Promise<Object|null>} - Возвращает промис, который разрешается объектом пользователя при успешном входе или null при ошибке.
 */
export async function signIn (email, password) {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password)
    // Signed in
    const user = userCredential.user
    console.log('Вход выполнен успешно:', user)
    return user
  } catch (error) {
    const errorCode = error.code
    const errorMessage = error.message
    console.error(`Error: ${errorCode} - ${errorMessage}`)
    return null
  }
}

/**
 * Выход пользователя из Firebase Authentication
 *
 * @returns {Promise<string>} - Возвращает промис, который разрешается сообщением об успешном выходе.
 */
export async function signOutUser () {
  try {
    await signOut(auth)
    console.log('Вы вышли из системы')
    return 'Выход выполнен'
  } catch (error) {
    console.error('Ошибка при выходе:', error)
  }
}

/**
 * Получение данных пользователя из Firebase Realtime Database
 * Если пользователя нет, то он добавляется в 'users' с правами reader
 *
 * @param {Object} user - Объект пользователя Firebase.
 * @returns {Promise<Object>} - Возвращает промис, который разрешается данными пользователя.
 */
export async function getUser (user) {
  const userRef = ref(database, 'users/' + user.uid)
  try {
    const snapshot = await get(userRef)
    if (snapshot.exists()) {
      const userData = snapshot.val()
      return userData
    } else {
      const userToSave = {
        [map.users.props.key]: user.uid,
        [map.users.props.email]: user.email,
        [map.users.props.role]: map.roles.reader // Assigning 'reader' role as default
      }
      set(userRef, userToSave)
      console.log('Создан новый пользователь: ' + userToSave.email)
      return userToSave
    }
  } catch (error) {
    console.error('Ошибка доступа к данным пользователя:', error)
  }
}

/// /////////////////////////////// USER MARKS /////////////////////////////////////

/**
 * Добавляет или удаляет entityId (равное ключу selectedRecordKey) к/из метки в Firebase Realtime Database.
 *
 * @param {string} pathToMark - Пусть к метке
 * @param {string} markKey - Ключ метки, к которой нужно добавить или из которой нужно удалить entityId.
 * @param {string} selectedRecordKey - Ключ записи, который нужно добавить или удалить в метке.
 * @param {boolean} isAdding - Указывает, добавляем или удаляем entityId.
 */
export async function updateEntityInMarkFB (pathToMark, markKey, selectedRecordKey, isAdding) {
  try {
    // Формируем обновление для добавления или удаления ключа записи
    const updates = {}
    updates[`${pathToMark}/${markKey}/entities/${selectedRecordKey}`] = isAdding ? selectedRecordKey : null

    await update(dbRef, updates)
    const action = isAdding ? 'добавлен' : 'удален'
    console.log(`Entity с ключом "${selectedRecordKey}" успешно ${action} ${isAdding ? 'к' : 'из'} метке "${markKey}".`)
  } catch (error) {
    const action = isAdding ? 'добавлении' : 'удалении'
    console.error(`Ошибка при ${action} entity с ключом "${selectedRecordKey}" ${isAdding ? 'к' : 'из'} метке "${markKey}":`, error)
    throw error
  }
}

/**
 * Удаляет метку, с переносом всех её entities в метку для замены, если такая указана.
 *
 * @param {Object} markData - Данные метки, включая entities.
 * @param {Object} replaceData - Метка для замены, в которую нужно перенести entities, если определена.
 * @param {string} pathToMark - Путь к метке пользователя.
 */
export async function removeMarkWithReplacementFB (markData, replaceData, pathToMark) {
  try {
    const { key: markKey, entities: entitiesToMove } = markData

    if (replaceData.key && replaceData.value) {
      // Переносим все entities в метку для замены
      const { key: replaceMarkKey, entities: replacementEntities = {} } = replaceData
      const updatedEntities = { ...replacementEntities }

      // Добавляем все entities, если их ещё нет в метке для замены
      for (const entityKey of Object.keys(entitiesToMove)) {
        if (!updatedEntities[entityKey]) {
          updatedEntities[entityKey] = entityKey
        }
      }

      // Обновляем метку для замены в Firebase
      const updates = {}
      updates[`${pathToMark}/${replaceMarkKey}/entities`] = updatedEntities

      await update(dbRef, updates)
      console.log(`Entities успешно перенесены в метку для замены с ключом "${replaceMarkKey}".`)
    }

    // Удаляем исходную метку
    const deleteUpdates = {}
    deleteUpdates[`${pathToMark}/${markKey}`] = null
    await update(dbRef, deleteUpdates)
    console.log(`Метка с ключом "${markKey}" успешно удалена.`)
  } catch (error) {
    console.error(`Ошибка при удалении метки с ключом "${markData.key}":`, error)
    throw error
  }
}
