// Реализация поиска используя fuse.js

import Fuse from 'fuse.js'
import { loadOriginalDataToTable, tableAlertAndUpdate, getOriginalData, setDataToTable } from './uiControl/tabulatorControl'

const options = {
  keys: ['description', 'title', 'date'],
  threshold: 0.3,
  includeMatches: true,
  ignoreLocation: true,
  includeScore: true
}

export function searchData (event) {
  const keyword = event.target.value
  if (event.key === 'Enter') {
    event.preventDefault()
    tableAlertAndUpdate(() => setSearch(keyword))
  }
}

function setSearch (keyword) {
  if (keyword) {
    // string is not empty
    console.time('Время поиска')
    const fuse = new Fuse(getOriginalData(), options)
    const results = fuse.search(keyword)
    const fullData = enhanceDataWithLongestMatches(results)
    setDataToTable(fullData)
    console.timeEnd('Время поиска')
  } else {
    loadOriginalDataToTable()
  }
}

// Данная фукнция выбирает наилучшее совпадение из всех результатов и оставляет только самое длинное совпадение.
// Нужно для подсветки искомого слова
function enhanceDataWithLongestMatches (results) {
  return results.map(result => {
    const longestMatchesPerKey = {}
    result.matches.forEach(match => {
      const key = match.key
      const longestMatch = match.indices.reduce((longest, current) => {
        const currentLength = current[1] - current[0] + 1
        const longestLength = longest ? longest[1] - longest[0] + 1 : 0
        return !longest || currentLength > longestLength ? current : longest
      }, null)

      longestMatchesPerKey[key] = longestMatch
    })

    return {
      ...result.item,
      longestMatches: longestMatchesPerKey
    }
  })
}

/**
 * Если вы сохраняете данные обратно в базу данных, вам нужно удалить информацию о resultsWithLongestMatches,
 * чтобы избежать сохранения лишних данных. Вы можете написать функцию,
 * которая удаляет longestMatches из данных перед сохранением их в базу:
 */
// function removeLongestMatches (data) {
//   return data.map(item => {
//     const { longestMatches, ...rest } = item
//     return rest
//   })
// }
