// файл для создания элементов с выпадающим списком в карточке записи

import Modal from 'bootstrap/js/dist/modal'
import Tagify from '@yaireo/tagify'
import { map } from '../constants.js'
import { updateFromAllEntities, removeItemFromFirebase, updateItemInFirebase, addItemToFirebase, updateEntityInMarkFB, removeMarkWithReplacementFB } from '../server/firebase.js'
import { getDataFromTable, updateTable, table } from './tabulatorControl.js'
import { showOkToast, showErrorToast } from './toastControl.js'
import { updateValidationState } from './rightPanel.js'
import { tagifyElements, user } from '../app.js'
import { isUserMarkElement, updateRecordMarksInTable, getFirebasePathToMark } from '../userMarksUtils.js'
// переменная для хранения идентификатора таймаута, который используется для контроля частоты кликов
// это предотвращает ситуации, когда функция обработчика клика вызывается слишком часто
let clickDebounce
let modalTagify

/**
 * Создает элемент для dropdown списка
 * @param {Object} mapLookup список элементов из базы
 * @param {String} property название списка с его свойствами
 * @returns обьект tagify
 */
export function createSelectTagify (mapLookup, property) {
  const el = document.getElementById(property.elementId)
  const tagify = new Tagify(el, getTagifyOptions(mapLookup, property))

  tagify.on('add', onAddTag)
    .on('edit:updated', onEditUpdatedTag)
    .on('invalid', onInvalidTag)
    .on('edit:input', onEditInput)

  if (user.role !== map.roles.reader && !property.isSingle && property.isPainted) { // условия для того, чтобы цвет по клику меялся только у тегов
    tagify.on('click', handleTagClick)
      .on('dblclick', () => { // Событие "dblclick" предотвращает изменение цвета тега при двойном клике
        clearTimeout(clickDebounce) // При двойном клике отменяем действие изменения цвета тега
      })
  }

  if (!property.isSingle && !property.isPainted) { // условия для списка писаний для добавления в тег автора при редактировании
    tagify.on('edit:start', onEditStart)
    tagify.on('edit:beforeUpdate', onEditBeforeUpdated)
  }
  if (property.listName === map.marks.listName) { // для пользовательских меток
    tagify.on('remove', onRemoveTag)
  }

  tagify.settings.hooks.suggestionClick = (e) => handleSuggestionClickEvent(tagify, e)

  return tagify
}

function getTagifyOptions (arrayList, property) {
  const options = {
    whitelist: arrayList, //  список для dropdown list
    addTagOnBlur: false,
    addTagOn: ['enter'],
    autoComplete: {
      rightKey: true // If true, when → is pressed, use the suggested value to create a tag, else just auto-completes the input.
    },
    dropdown: {
      enabled: 1, // show the dropdown immediately on focus if 0
      maxItems: 7,
      highlightFirst: true,
      ...(property.listName === 'scriptures' && { searchKeys: ['value', 'author'] }) // если есть поле автора, то искать также и по автору
    },
    templates: {
      closeOnSelect: property.isSingle,
      dropdownItem: suggestionItemTemplate // кастомный шаблон для элементов выпадающего списка
    },
    editTags: {
      keepInvalid: true // keeps invalid edits as-is until esc is pressed while in focus
    },
    createInvalidTags: false, // If true, create invalid-tags. Otherwise, keep the editable input and do not create tags from it
    validate: validateTag,
    role: property
  }
  options.transformTag = transformTag
  return options
}

function transformTag (tagData) {
  if (!this.settings.role.isPainted) { return }

  if (!tagData.color) {
    const { color, bgColor } = generateColors()
    tagData.color = color
    tagData.bgColor = bgColor
  }

  tagData.style = `--tag-bg: ${tagData.bgColor}; --tag-text-color: ${tagData.color};`
}

/**
 * Обрабатывает событие добавления нового тега в Tagify.
 * Если это новый тег (под тегом имеется в виду элемент из любого списка), то он сохраняется в базу.
 * Если этот Tagify относится к пользовательским меткам, то происходит их сохранение в базу или обновление.
 * Пользовательские метки сохраняются и обновляются в базе автоматически.
 * @param {Object} event.detail - Детали события добавления тега.
 * @param {Object} event.detail.tagify - Экземпляр Tagify, в который добавляется тег.
 * @param {Object} event.detail.data - Данные нового тега.
 * @param {Object} event.detail.tag - DOM элемент тега, который был добавлен.
 */
async function onAddTag ({ detail: { tagify, data, tag } }) {
  // Получаем конфигурацию списка для текущего элемента Tagify
  const property = tagify.settings.role

  // Если поле является одиночным (isSingle), удаляем предыдущие теги, оставляя только один.
  if (property.isSingle && tagify.value.length > 1) {
    tagify.removeTags(undefined, undefined, 0)
  }

  const selectedRecord = table.getSelectedData()[0]
  // Ключ выбранной записи в таблице
  const selectedRecordKey = selectedRecord?.key
  // Проверяем, является ли это пользовательской меткой
  const isUserMark = isUserMarkElement(property.elementId)

  // Очищаем данные для сохранения тега на основе свойств в map из Constats (props)
  const cleanData = getCleanData(data, property.props)

  // Определяем путь для сохранения данных. Для пользовательских меток путь зависит от UID пользователя.
  const path = isUserMark ? getFirebasePathToMark(user.key) : property.listName

  try {
    // Включаем индикатор загрузки для тега
    tagify.tagLoading(tag, true)

    if (isUserMark) {
      // Если это пользовательская метка
      if (!data.key) {
        // Новая метка. Поле entities У нее пустое
        cleanData.entities = { [selectedRecordKey]: selectedRecordKey }
        // Обновляем метку в таблице
        updateRecordMarksInTable(selectedRecord, cleanData)

        cleanData.key = await addItemToFirebase(cleanData, path)
        // Обновляем Tagify с новыми данными
        updateTagifyData(tagify, cleanData, tag)
      } else {
        // Существующий тег. Поле entities может быть заполнено, поэтому надо доставать из whitelist
        cleanData.entities = cleanData.entities ?? {}

        if (!(selectedRecordKey in cleanData.entities)) {
          cleanData.entities[selectedRecordKey] = selectedRecordKey
          await updateEntityInMarkFB(path, cleanData.key, selectedRecordKey, true)
          // const msg = await updateItemInFirebase(cleanData, path)

          // Обновляем метки в таблице
          updateRecordMarksInTable(selectedRecord, cleanData)
          // Обновляем Tagify с новыми данными
          updateTagifyData(tagify, cleanData, tag)
        }
      }
    } else {
      // Если это не пользовательская метка
      if (!data.key) {
        // Новый тег
        cleanData.key = await addItemToFirebase(cleanData, path)
        // Обновляем Tagify с новыми данными
        updateTagifyData(tagify, cleanData, tag)
      }
      // Для существующих тегов не требуется дополнительное действие
    }

    // Отключаем индикатор загрузки
    tagify.tagLoading(tag, false)
  } catch (error) {
    console.error(`Ошибка при сохранении нового элемента: '${data.value}'`, error)
    showErrorToast('Возникла ошибка при сохранении нового элемента')
  }
}

/**
 * Обрабатывает событие обновления тега.
 * @param {Object} e - Объект события, содержащий информацию о теге.
 */
async function onEditUpdatedTag ({ detail: { tagify, data, tag } }) {
  if (!tagify.editTagChangeDetected(tagify.getSetTagData(tag))) {
    return
  }

  try {
    tagify.tagLoading(tag, true)
    const property = tagify.settings.role
    const cleanData = getCleanData(data, property.props)

    // Проверяем, является ли это пользовательской меткой
    const isUserMark = isUserMarkElement(property.elementId)

    // Получаем путь для обновления данных в зависимости от типа метки (пользовательская или нет)
    const path = isUserMark ? getFirebasePathToMark(user.key) : property.listName

    // Обновляем whitelist в Tagify
    const existingIndex = tagify.whitelist.findIndex(t => t.key === cleanData.key)
    if (isUserMark) { // если это метка, то добавляем к ней все уже существующие связи с лекциями
      const existingItem = tagify.whitelist[existingIndex]
      cleanData.entities = { ...existingItem.entities, ...cleanData.entities }
    }
    // Обновляем данные в Firebase
    await updateItemInFirebase(cleanData, path)

    tagify.whitelist[existingIndex] = cleanData
    tagify.dropdown.refilter.call(tagify)

    const fieldsWithSameList = getFieldsByListName(property.listName) //  это нужно в случае изменения списка людей, когда обновления подхватываются и в "автор", и в "чтец"
    updateRecordsInTable(cleanData, fieldsWithSameList, property.isSingle)

    tagify.tagLoading(tag, false)
  } catch (error) {
    console.error(`Ошибка при обновлении "${data.value}" в базе данных:`, error)
    showErrorToast('Возникла ошибка при обновлении')
  }
}

function onEditInput ({ detail: { tagify, tag, data } }) {
  const duplicated = checkForDuplicates(tagify.whitelist, data.newValue, data.key)
  tag.classList.toggle(tagify.settings.classNames.tagInvalid, duplicated === true)
}

function onInvalidTag ({ detail: { tagify, tag } }) {
  const editableElm = tagify.getTagTextNode(tag)
  editableElm.focus()
}

function validateTag (e) {
  if (e.key) {
    const duplicated = checkForDuplicates(this.whitelist, e.value, e.key)
    return duplicated ? 'Запись с таким именем уже существует' : true
  }
  return true
}

function handleTagClick ({ detail: { tagify, tag, data } }) {
  clearTimeout(clickDebounce)
  clickDebounce = setTimeout(async () => {
    tagify.tagLoading(tag, true)
    const property = tagify.settings.role
    const { color, bgColor } = generateColors()
    data.color = color
    data.bgColor = bgColor
    data.style = `--tag-bg: ${data.bgColor}; --tag-text-color: ${data.color};`
    const cleanData = getCleanData(data, property.props)
    try {
      const isUserMark = isUserMarkElement(property.elementId)
      const path = isUserMark ? getFirebasePathToMark(user.key) : property.listName
      const whitelistIndex = tagify.whitelist.findIndex(item => item.key === cleanData.key)
      if (isUserMark) { // если это метка, то добавляем к ней все уже существующие связи с лекциями
        const existingItem = tagify.whitelist[whitelistIndex]
        cleanData.entities = { ...existingItem.entities, ...cleanData.entities }
      }
      await updateItemInFirebase(cleanData, path)
      tagify.replaceTag(tag, data)
      tagify.whitelist[whitelistIndex] = cleanData
    } catch (error) {
      console.log(`Ошибка при обновлении в базе: '${error}'`)
      showErrorToast('Ошибка при обновлении записи в базе')
    }
    const fieldsWithSameList = getFieldsByListName(property.listName)
    updateRecordsInTable(cleanData, fieldsWithSameList, property.isSingle)
    tagify.tagLoading(tag, false)
  }, 200)
}

function onEditStart ({ detail: { tagify, tag, data } }) {
  const tagText = data.author ? `${data.value}, ${data.author}` : data.value
  tagify.setTagTextNode(tag, tagText)
}

function onEditBeforeUpdated ({ detail: { tagify, data } }) {
  if (tagify.settings.role.fieldName === map.scriptures.fieldName) { // если это писания
    const [value, author = ''] = parseFullValue(data.value)
    data.value = value
    data.author = author
  }
}

/**
 * Обрабатывает удаление тега из Tagify.
 * @param {Object} event - Детали события удаления тега.
 */
async function onRemoveTag ({ detail: { tagify, tag, data } }) {
  try {
    const role = tagify.settings.role
    const cleanData = getCleanData(data, role.props)
    const existingIndex = tagify.whitelist.findIndex(t => t.key === cleanData.key)
    const existingItem = tagify.whitelist[existingIndex]
    cleanData.entities = { ...existingItem.entities, ...cleanData.entities } // добавляем все entities

    const selectedRecord = table.getSelectedData()[0]
    const selectedRecordKey = selectedRecord?.key

    delete cleanData.entities[selectedRecordKey]

    // Определяем путь для обновления данных
    const path = getFirebasePathToMark(user.key)
    await updateEntityInMarkFB(path, cleanData.key, selectedRecordKey, false) // Улаляем только конкретную запись данной лекции
    console.log(`Из метки "${cleanData.value}" удалена запись с ключом "${selectedRecordKey}".`)
    // Обновляем метки в таблице
    if (selectedRecord.marks) {
      // Создаем копию массива меток
      const marks = [...selectedRecord.marks]
      // Находим индекс метки, которую нужно удалить
      const markIndex = marks.findIndex(mark => mark.key === cleanData.key)
      if (markIndex !== -1) {
        // Удаляем метку из массива
        marks.splice(markIndex, 1)

        // Обновляем строку в таблице с новым массивом меток
        table.updateRow(selectedRecord.key, { marks })
      }
    }

    updateTagifyData(tagify, cleanData, tag)
  } catch (error) {
    console.error('Ошибка при удалении записи из метки:', error)
    showErrorToast('Возникла ошибка при удалении записи из метки')
  }
}

/**
 * Генерирует HTML-шаблон для элемента выпадающего списка с предложением (тегом).
 * @param {Object} tagData - Данные тега, передаваемые библиотекой Tagify.
 * @returns {string} - HTML-шаблон элемента списка с кнопкой "Удалить".
 */

function handleSuggestionClickEvent (tagify, e) {
  const isAction = e.target.classList.contains('remove-suggestion-btn')
  const keyToRemove = isAction ? e.target.dataset.key : null
  const valueToRemove = isAction ? e.target.dataset.value : null

  return new Promise((resolve, reject) => {
    if (!isAction) {
      return resolve()
    }

    const role = tagify.settings.role
    const isRequired = checkIsRequired(role.listName)
    const modal = new Modal(document.getElementById('confirmDeleteModal'))
    const modalEl = document.getElementById('confirmDeleteModal')
    modalEl.addEventListener('hide.bs.modal', event => {
      tagify.dropdown.hide()
      modalTagify.removeAllTags()
      requiredTextEl.classList.add('d-none')
      updateValidationState(inputEl, true, '', modalTagify)
      checkEl.removeAttribute('disabled')
      inputEl.classList.remove('is-valid')
    })

    const inputEl = document.getElementById('replacementTagify')
    const delBtn = document.getElementById('confirmDeleteBtn')
    const checkEl = document.getElementById('replaceOptionCheckbox')
    const requiredTextEl = document.getElementById('requiredText')
    const modalBodyEl = document.querySelector('#confirmDeleteModal .modal-body p') // Directly target the <p> element

    modalBodyEl.innerHTML = `
                             <p>Это действие приведет к удалению <strong> "${valueToRemove}"</strong> во всех записях.</p>
                            `
    checkEl.checked = false
    const whitelist = [...tagify.whitelist]
    const indexToRemove = whitelist.findIndex(item => item.key === keyToRemove)
    whitelist.splice(indexToRemove, 1) // убираем из списка удаляемую запись, чтобы не было возможности заменить саму на себя

    if (!modalTagify) {
      modalTagify = new Tagify(inputEl, {
        whitelist,
        mode: 'select',
        enforceWhitelist: true,
        dropdown: { classname: 'dropdown_inside_module', highlightFirst: true }
      })
      modalTagify.on('add', () => {
        if (isRequired) requiredTextEl.classList.add('d-none')
        inputEl.classList.toggle('is-valid', true)
        updateValidationState(inputEl, true, '', modalTagify)
      })
      modalTagify.on('remove', () => {
        if (checkEl.checked) {
          inputEl.classList.remove('is-valid')
          updateValidationState(inputEl, false, 'необходимо выбрать замену', modalTagify)
        }
      })
    } else modalTagify.whitelist = whitelist

    modalTagify.setDisabled(true)
    if (isRequired) {
      checkEl.checked = true
      checkEl.setAttribute('disabled', '')
      modalTagify.setDisabled(false)
      requiredTextEl.classList.remove('d-none')
    }
    checkEl.addEventListener('change', function (e) {
      if (this.checked) {
        modalTagify.setDisabled(false)
      } else {
        modalTagify.setDisabled(true)
        updateValidationState(inputEl, true, '', modalTagify)
      }
    })

    delBtn.onclick = async () => {
      const item = modalTagify.value[0]

      if (!item && isRequired) {
        requiredTextEl.classList.add('d-none')
        inputEl.classList.remove('is-valid')
        updateValidationState(inputEl, false, 'необходимо выбрать замену, поскольку поле является обязательным.', modalTagify)
        return
      }

      if (checkEl.checked && !item) {
        updateValidationState(inputEl, false, 'необходимо выбрать замену', modalTagify)
        return
      }
      delBtn.setAttribute('disabled', '')
      const spinnerEl = delBtn.querySelector('span.spinner-border')
      spinnerEl.classList.remove('d-none')
      const cleanItem = item ? getCleanData(item, role.props) : null
      const dataForReplace = checkEl.checked ? cleanItem : { key: null, value: null }
      await removeItem(keyToRemove, tagify, dataForReplace)
      modal.hide()
      spinnerEl.classList.add('d-none')
      delBtn.removeAttribute('disabled')
    }
  })
}

/**
 * Удаляет тег из списка тегов, из базы данных и из всех сущностей в таблице.
 * @param {string} keyToRemove - Ключ тега, который необходимо удалить.
 * @param {string} valueToRemove - Значение тега, которое необходимо удалить.
 * @param {Tagify} tagify - Экземпляр Tagify, управляющий тегами.
 */

async function removeItem (keyToRemove, tagify, replaceData) {
  const indexToRemove = tagify.whitelist.findIndex(item => item.key === keyToRemove)
  const itemToRemove = tagify.whitelist[indexToRemove]

  if (indexToRemove !== -1) {
    try {
      const property = tagify.settings.role
      // Проверяем, является ли элемент пользовательской меткой
      const isUserMark = isUserMarkElement(property.elementId)

      updateWhitelistAndTags(isUserMark, tagify, indexToRemove, keyToRemove, replaceData)
      const fieldsWithSameList = getFieldsByListName(property.listName)
      if (isUserMark) {
        removeMarkWithReplacementFB(itemToRemove, replaceData, getFirebasePathToMark(user.key))
      } else {
        const resultFromFB = await removeItemFromFirebase(keyToRemove, property.listName)
        console.log(`${resultFromFB}`)
        const resultFromEntitiesFB = await updateFromAllEntities(keyToRemove, replaceData.key, fieldsWithSameList, property.isSingle)
        console.log(`Из базы удалено ${resultFromEntitiesFB} записей`)
      }
      updateRecordsInTable({ key: keyToRemove, value: null }, fieldsWithSameList, property.isSingle, replaceData)

      // Проверка и добавление тега в другие элементы tagify с таким же listName (например чтец и автор)
      Object.values(tagifyElements).forEach(tg => {
        if (tg.settings.role.listName === property.listName) {
          const otherIndexToRemove = tg.whitelist.findIndex(item => item.key === keyToRemove)
          if (otherIndexToRemove !== -1) {
            updateWhitelistAndTags(tg, otherIndexToRemove, keyToRemove, replaceData)
          }
        }
      })

      showOkToast(`Запись '${itemToRemove.value}' удалена`)
    } catch (error) {
      console.error('Ошибка при удалении:', error)
      tagify.whitelist.splice(indexToRemove, 0, itemToRemove)
      tagify.dropdown.refilter.call(tagify)
      showErrorToast(`Ошибка при удалении '${itemToRemove.value}'`)
    }
  }
}

function updateWhitelistAndTags (isUserMark, tagify, indexToRemove, keyToRemove, replaceData) {
  // Если это метка, переносим все сущности из старой метки в метку для замены
  if (isUserMark && replaceData && replaceData.key && replaceData.value) {
    const itemToRemove = tagify.whitelist[indexToRemove]
    if (itemToRemove && itemToRemove.entities) {
      Object.keys(itemToRemove.entities).forEach(entityId => {
        if (!replaceData.entities) {
          replaceData.entities = {}
        }
        if (!(entityId in replaceData.entities)) {
          replaceData.entities[entityId] = entityId
        }
      })
    }
    const existingIndex = tagify.whitelist.findIndex(item => item.key === replaceData.key)
    tagify.whitelist[existingIndex] = { ...tagify.whitelist[existingIndex], ...replaceData }
  }

  // Удаляем старую метку из whitelist
  tagify.whitelist.splice(indexToRemove, 1)
  tagify.dropdown.refilter.call(tagify)
  tagify.dropdown.hide()
  tagify.DOM.input.textContent = ''

  // Обновляем метку для замены в whitelist
  if (replaceData && replaceData.key && replaceData.value) {
    const currentTags = tagify.value
    const updatedTags = currentTags.map(tag =>
      tag.key === keyToRemove ? replaceData : tag
    ).filter(Boolean)
    tagify.removeAllTags()
    if (updatedTags.length > 0) {
      tagify.addTags(updatedTags)
    }

    // Обновляем whitelist с меткой для замены

    // if (existingIndex !== -1) {
    // } else {
    //   tagify.whitelist.push(replaceData)
    // }
  }
}

function checkIsRequired (listName) {
  for (const key in map) {
    if (map[key] && map[key].listName === listName && map[key].isRequired) {
      return true
    }
  }
  return false
}

function getFieldsByListName (targetListName) {
  const matchingRoles = Object.entries(map).filter(([, value]) =>
    value.listName === targetListName
  ).map(([, value]) => value.fieldName)

  return matchingRoles
}

function updateRecordsInTable (item, fields, isSingle, replaceData) {
  const data = getDataFromTable()
  let updatedRows = []

  // Common filter logic for both isSingle true and false cases
  updatedRows = data.filter(row =>
    fields.some(field =>
      isSingle
        ? row[field] && row[field].key === item.key
        : Array.isArray(row[field]) && row[field].some(tag => tag.key === item.key)
    )
  )

  // After filtering rows that match the condition for any of the fields,
  // apply updates based on isSingle
  if (isSingle) {
    updatedRows = updatedRows.map(row => {
      const updatedRow = { ...row }
      fields.forEach(field => {
        if (row[field] && row[field].key === item.key) {
          updatedRow[field] = item.value ? item : null // Update or nullify the field
          if (replaceData?.key) {
            updatedRow[field] = replaceData
          }
        }
      })
      return updatedRow
    })
  } else {
    // For non-isSingle case, update or push replaceData within the fields
    updatedRows.forEach(row => {
      fields.forEach(field => {
        if (Array.isArray(row[field])) {
          const tagIndex = row[field].findIndex(tag => tag.key === item.key)
          if (tagIndex > -1) {
            item.value === null ? row[field].splice(tagIndex, 1) : row[field][tagIndex] = item
          }

          if (replaceData?.key && !row[field].some(tag => tag.key === replaceData.key)) {
            row[field].push(replaceData)
          }
        }
      })
    })
  }

  if (updatedRows.length > 0) {
    updateTable(updatedRows)
  }
}

/**
 * Обновляет данные в Tagify после добавления нового тега.
 * @param {Object} tagify - Экземпляр Tagify.
 * @param {Object} cleanData - Данные нового тега.
 * @param {Object} tag - DOM элемент тега, который был добавлен.
 */
function updateTagifyData (tagify, cleanData, tag) {
  // Обновляем whitelist в Tagify для отображения новой метки в выпадающем списке
  // Проверяем, существует ли элемент с таким же 'value' в 'whitelist'
  const existingIndex = tagify.whitelist.findIndex(item => item.value === cleanData.value)

  if (existingIndex !== -1) {
  // Если элемент найден, обновляем его
    tagify.whitelist[existingIndex] = { ...tagify.whitelist[existingIndex], ...cleanData }
  } else {
  // Если элемент не найден, добавляем новый
    tagify.whitelist.push(cleanData)
  }

  // Ищем добавленный тег по его значению и добавляем ему созданный ключ
  const tagIndex = tagify.value.findIndex(tag => tag.value === cleanData.value)
  if (tagIndex > -1) {
    tagify.value[tagIndex].key = cleanData.key

    // Обновляем данные тега в поле ввода tagify
    tagify.getSetTagData(tag, cleanData, false)
  }
}

function generateColors () {
  function rand (min, max) {
    return min + Math.random() * (max - min)
  }

  const h = rand(0, 360) | 0 // Hue: охватывает все диапазоны цветов
  const s = rand(55, 60) | 0 // Saturation: низкая насыщенность для пастельности
  const l = rand(35, 40) | 0 // Lightness: низкая яркость

  const risedLightness = Math.min(l + 50, 100)
  return {
    color: `hsl(${h}, ${s}%, ${l}%)`, bgColor: `hsl(${h}, ${s}%, ${risedLightness}%)`
  }
}
function getCleanData (obj, wantedProps) {
  const cleanObj = {}
  wantedProps.forEach(prop => {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      cleanObj[prop] = obj[prop]
    }
  })
  return cleanObj
}

function checkForDuplicates (tagArray, v, k) {
  return tagArray.some(({ value, key }) => value.toLowerCase() === v.toLowerCase() && key !== k)
}

function parseFullValue (value) {
  return value.split(',').map(part => part.trim())
}

function suggestionItemTemplateOLD (tagData) {
  const tagStyle = `background-color: ${tagData.bgColor || '#F3F3F3'}; color: ${tagData.color || 'black'}; border-radius: 3px; padding: 0.3em 0.5em; display: inline-block; margin: 0.2em;` // Стиль для "тега"

  return `
    <div ${this.getAttributes(tagData)} 
        class='tagify__dropdown__item d-flex justify-content-between align-items-center' 
        tabindex="0" 
        role="option">
      <div class='flex-grow-1'>
        <div class='tag-item' style="${tagStyle}">${tagData.value}</div> 
        <div class='text-muted small'>${tagData.author ? tagData.author : ''}</div>
      </div>
      <div>
        <a class="btn remove-suggestion-btn bi bi-trash3"
          data-key="${tagData.key}" 
          data-value="${tagData.value}"
          tabindex="0"
          role="button"
          title="Удалить" 
          data-bs-toggle="modal" 
          data-bs-target="#confirmDeleteModal"
        >
        </a>
      </div>
    </div>
  `
}

function suggestionItemTemplate (tagData) {
  const tagStyle = `background-color: ${tagData.bgColor || '#F3F3F3'}; color: ${tagData.color || 'black'}; border-radius: 3px; padding: 0.3em 0.5em; display: inline-block; margin: 0.2em;` // Стиль для "тега"

  return `
    <div ${this.getAttributes(tagData)} 
        class='tagify__dropdown__item d-flex justify-content-between align-items-center' 
        tabindex="0" 
        role="option">
      <div class='flex-grow-1'>
        <!-- Основной тег и автор в одном блоке -->
        <div class='tag-item' style="${tagStyle}">
          <div>${tagData.value}</div>
          ${tagData.author ? `<div  class="text-muted small">${tagData.author}</div>` : ''}
        </div> 
      </div>
      <div>
        <a class="btn remove-suggestion-btn bi bi-trash3"
          data-key="${tagData.key}" 
          data-value="${tagData.value}"
          tabindex="0"
          role="button"
          title="Удалить" 
          data-bs-toggle="modal" 
          data-bs-target="#confirmDeleteModal"
        >
        </a>
      </div>
    </div>
  `
}
