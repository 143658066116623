// import * as bootstrap from 'bootstrap'
import Toast from 'bootstrap/js/dist/toast'

const toastLive = document.getElementById('liveToast')

export function showToast (message, type) {
  // Setting up the toast header color and icon based on the type
  let headerClass = 'bg-success bg-opacity-75'
  let iconHtml = '<i class="bi bi-check2-circle me-2"></i>' // Success icon by default
  let strongMessage = 'Выполнено' // Default message

  if (type === 'error') {
    headerClass = 'bg-danger bg-opacity-75'
    iconHtml = '<i class="bi bi-exclamation-triangle-fill me-2"></i>' // Error icon
    strongMessage = 'Ошибка'
  }

  // Constructing the header HTML
  const headerHtml = `
    <div class="toast-header text-light ${headerClass}">
      ${iconHtml}
      <strong class="me-auto">${strongMessage}</strong>
      <button type="button" class="btn-close btn-close-white" data-bs-dismiss="toast" aria-label="Закрыть"></button>
    </div>
  `

  // Setting the toast's header and body
  toastLive.querySelector('.toast-header').outerHTML = headerHtml
  toastLive.querySelector('.toast-body').textContent = message

  // Showing the toast
  const toast = new Toast(toastLive)
  toast.show()
}

export function showOkToast (message) {
  showToast(message, 'ok')
}

export function showErrorToast (message) {
  showToast(message, 'error')
}
