const leftPanel = document.getElementById('left-panel')
export const rightPanel = document.getElementById('right-panel')
const divider = document.getElementById('divider')

leftPanel.style.width = '100%' // Set the initial width of the left panel

/**
Divider
*/
divider.addEventListener('mousedown', (e) => {
  e.preventDefault()
  document.documentElement.style.setProperty('--cursor', 'ew-resize')
  document.documentElement.style.setProperty('--user-select', 'none')

  document.addEventListener('mousemove', onMouseMove)
  document.addEventListener('mouseup', onMouseUp)
})

// Update the panels' width while dragging
function onMouseMove (e) {
  const newWidth = e.clientX - leftPanel.getBoundingClientRect().left
  leftPanel.style.width = `${newWidth}px`
  rightPanel.style.width = `calc(100% - ${newWidth}px - 6px)` // Adjust right panel width
}

// Clean up after dragging has ended
function onMouseUp () {
  document.documentElement.style.setProperty('--cursor', '')
  document.documentElement.style.setProperty('--user-select', '')

  document.removeEventListener('mousemove', onMouseMove)
  document.removeEventListener('mouseup', onMouseUp)
}

export function openRightPanel () {
  const screenWidth = window.innerWidth

  if (isMobile()) {
    rightPanel.style.width = '100%' // На мобильных устройствах правая панель занимает 100%
    leftPanel.style.width = '0%' // Ширина левой панели
  } else if (screenWidth <= 1024) {
    rightPanel.style.width = '500px' // Фиксированная ширина для экранов < 1024px
    leftPanel.style.width = 'calc(100% - 500px)' // Оставляем остальное пространство для левой панели
  } else {
    rightPanel.style.width = '35%' // Обычная ширина панели
    leftPanel.style.width = '65%' // Ширина левой панели
  }

  rightPanel.classList.remove('d-none') // Открываем правую панель
}

export function closeRightPanel () {
  rightPanel.classList.add('d-none')
  leftPanel.style.width = '100%' // Левая панель занимает всю ширину
}

// Helper function to check if the device is mobile
function isMobile () {
  return /Mobi|Android/i.test(navigator.userAgent) || window.innerWidth < 768 // Простой способ определить мобильное устройство
}
