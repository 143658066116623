import IMask from 'imask'
import { DateTime } from 'luxon'

// const duration = document.getElementById('duration')
const maskOptionsTime = {
  mask: 'HH:MM:SS',
  // overwrite: true,
  lazy: false, // make placeholder always visible
  blocks: {
    HH: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 12,
      autofix: false
    },

    MM: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 59,
      autofix: true
    },

    SS: {
      mask: IMask.MaskedRange,
      from: 0,
      to: 59,
      autofix: true
    }
  }
}

// export const durationMask = IMask(duration, maskOptionsTime)

export function createMaskTime (inputEl) {
  const iMask = IMask(inputEl, maskOptionsTime)
  addCursorPositionEventListeners(inputEl)

  return {
    iMask,
    getTimeStr: function () {
      return formatTimeWithPlaceholders(iMask.value)
    }
  }
}

function formatTimeWithPlaceholders (timeStr) {
  // Split the input string into its components
  let [hours, minutes, seconds] = timeStr.split(':')

  // Handle hours - always a single digit and replaced with zero if missing
  hours = hours.replace(/_/g, '')
  hours = hours || '0' // Default to '0' if empty

  // Handle minutes and seconds with specific logic based on placeholders
  minutes = formatWithPlaceholderLogic(minutes)
  seconds = formatWithPlaceholderLogic(seconds)

  // Construct the formatted time string
  const formattedTime = `${hours.padStart(2, '0')}:${minutes}:${seconds}`

  return formattedTime
}

function formatWithPlaceholderLogic (timeComponent) {
  // If the component is fully missing or all underscores, default to "00"
  if (!timeComponent || /^_+$/.test(timeComponent)) {
    return '00'
  }
  // If the component ends with an underscore, replace it with a '0' at the end
  else if (/_$/.test(timeComponent)) {
    return timeComponent.replace(/_$/, '0')
  }
  // If the component starts with an underscore, replace it with a '0' at the start
  else if (/^_/.test(timeComponent)) {
    return timeComponent.replace(/^_/, '0')
  }
  // No underscores or already in correct format
  return timeComponent
}

/// ///////////////////////////// ДАТА //////////////////////////////////////

const currentYear = new Date().getFullYear()
// const dateEl = document.getElementById('date')
const maskOptionsDate = {
  mask: 'YYYY.MM.DD',
  // overwrite: true, // defaults to `false`
  lazy: false, // make placeholder always visible

  blocks: {
    YYYY: {
      mask: IMask.MaskedRange,
      from: 1995,
      to: currentYear,
      autofix: true
    },

    MM: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 12,
      autofix: true
    },

    DD: {
      mask: IMask.MaskedRange,
      from: 1,
      to: 31,
      autofix: true
    }
  }
}

// export const dateMask = IMask(dateEl, maskOptionsDate)

export function createMaskDate (inputEl) {
  const iMask = IMask(inputEl, maskOptionsDate)
  addCursorPositionEventListeners(inputEl)

  return {
    iMask,
    getDateRangeFromMask: function () {
      return parseDateInputWithRange(iMask.unmaskedValue)
    },
    getDate: function () {
      return parseDateInput(iMask.unmaskedValue)
    }
  }
}

// Function to add event listeners related to cursor position
function addCursorPositionEventListeners (inputEl) {
  // Handle cursor position on keydown
  inputEl.addEventListener('keydown', (e) => {
    if (['ArrowRight', 'ArrowDown', 'End'].includes(e.key)) {
      setCursorAfterLastDigit(inputEl)
      e.preventDefault()
    }
  })

  // Handle cursor position on mouse click
  inputEl.addEventListener('click', () => setCursorAfterLastDigit(inputEl))
}

// Function to set cursor after the last digit
function setCursorAfterLastDigit (inputEl) {
  const value = inputEl.value
  const lastDigitMatch = value.match(/\d(?=[^0-9]*$)/)
  const position = lastDigitMatch ? lastDigitMatch.index + 1 : 0
  inputEl.setSelectionRange(position, position)
}

function parseDateInput (inputValue) {
  let date
  if (inputValue.length === 8) {
    date = DateTime.fromFormat(inputValue, 'yyyyMMdd')
  } else {
    return false
  }
  return date.toFormat('yyyy.MM.dd')
}
function parseDateInputWithRange (inputValue) {
  let startDate, endDate

  if (inputValue.length === 4) { // Year only
    startDate = DateTime.fromObject({ year: parseInt(inputValue, 10), month: 1, day: 1 })
    endDate = DateTime.fromObject({ year: parseInt(inputValue, 10), month: 12, day: 31 })
  } else if (inputValue.length === 6) { // Year and month
    const year = parseInt(inputValue.substring(0, 4), 10)
    const month = parseInt(inputValue.substring(4, 6), 10)
    startDate = DateTime.fromObject({ year, month, day: 1 })
    endDate = startDate.plus({ months: 1 }).minus({ days: 1 }) // Get the last day of the month
  } else if (inputValue.length === 8) { // Specific date
    startDate = endDate = DateTime.fromFormat(inputValue, 'yyyyMMdd')
  } else {
    // Invalid headerValue format
    return false
  }
  return {
    startDate: startDate.toFormat('yyyy.MM.dd'),
    endDate: endDate.toFormat('yyyy.MM.dd')
  }
}
