// import * as bootstrap from 'bootstrap'
import Modal from 'bootstrap/js/dist/modal'
import { DateTime } from 'luxon'
import { map } from '../constants.js'
import { closeRightPanel } from './panels.js'
import { addItemToFirebase, updateItemInFirebase, removeItemFromFirebase } from '../server/firebase.js'
import { updateRowInTable, addRowInTable, deleteRow } from './tabulatorControl.js'
import { tagifyElements, inputElements, maskElements } from '../app.js'
import { showOkToast, showErrorToast } from './toastControl.js'
import { stopAudio } from '../audioPlayer.js'

const closeBtn = document.getElementById('close-btn')
const saveBtn = document.getElementById('save-btn')
const deleteBtn = document.getElementById('delete-btn')
const cancelBtn = document.getElementById('cancel-btn')

closeBtn.addEventListener('click', updateUIAndCLose)
cancelBtn.addEventListener('click', updateUIAndCLose)
saveBtn.addEventListener('click', save)
// delBtnModal.addEventListener('click', deleteItem)
deleteBtn.addEventListener('click', deleteItem)

function updateUIAndCLose () {
  resetMaskValidationUI()
  closeRightPanel()
  stopAudio()
}

// Функция для сбора данных с формы
function collectFormData () {
  const tagifyResults = {}
  for (const role of Object.keys(map)) {
    const tagify = tagifyElements[role]
    if (tagify) {
      if (map[role].isSingle) {
        tagifyResults[map[role].fieldName] = tagify.value[0] ?? null
      } else {
        tagifyResults[map[role].fieldName] = tagify.value
      }
    }
  }
  return {
    ...tagifyResults,
    key: inputElements.key.value,
    title: inputElements.title.value,
    comment: inputElements.comment.value,
    description: inputElements.description.textContent,
    youtube: inputElements.youtube.value,
    advayta: inputElements.advayta.value,
    date: maskElements.date.getDate(),
    duration: maskElements.duration.getTimeStr()
  }
}

// Обработчик событий для кнопки "Сохранить"

async function save () {
  const formData = collectFormData()
  if (!validateOnSave(formData)) {
    console.log('Заполните даные')
    return
  }
  const recordKey = formData.key

  const dataForFB = { ...formData }

  //  обновляет и подготавливает списки для сохранеия в базу
  for (const role of Object.keys(map)) {
    const config = map[role]
    if (!config.listName) continue
    if (config.isSingle) {
      dataForFB[config.fieldName] = dataForFB[config.fieldName] ? dataForFB[config.fieldName].key : null
    } else {
      if (Array.isArray(dataForFB[config.fieldName])) {
        dataForFB[config.fieldName] = dataForFB[config.fieldName].reduce((acc, tag) => {
          acc[tag.key] = tag.key
          return acc
        }, {})
      }
    }
  }

  // Если обновить
  if (recordKey) {
    updateRowInTable(recordKey, formData)
    try {
      await updateItemInFirebase(dataForFB, map.ENTITIES)
    } catch (error) {
      console.log(`Ошибка при обновлении '${error}'`)
      showErrorToast('Не удалось обновить запись в базе')
    }
  } else {
  // иначе создание новой записи
    try {
      formData.key = await addItemToFirebase(dataForFB, map.ENTITIES)
    } catch (error) {
      console.log(`Ошибка при создании '${error}'`)
      showErrorToast('Не удалось создать запись в базе')
    }
    addRowInTable(formData)
  }
  stopAudio()
  closeRightPanel()
}

async function deleteItem () {
  if (!inputElements.key.value) return // если запись еще не сохранена, то удалять еще нечего
  const modal = new Modal(document.getElementById('confirmDeleteModalRec'))
  const modalBodyEl = document.querySelector('#confirmDeleteModalRec .modal-body p')
  const title = inputElements.title.value
  const date = maskElements.date.getDate()
  const type = tagifyElements.type.value[0].value
  modalBodyEl.innerHTML = `
              <figure class="text-center">
              <blockquote class="blockquote">
                <p>${date} ${type} ${title}</p>
              </blockquote>
              <figcaption class="blockquote-footer">
                удалить навсегда?
              </figcaption>
            </figure>
            `
  const delBtnModal = document.getElementById('confirmDeleteBtnRec')

  modal.show()
  delBtnModal.onclick = async () => {
    try {
      const spinnerEl = delBtnModal.querySelector('span.spinner-border')
      spinnerEl.classList.remove('d-none')
      delBtnModal.setAttribute('disabled', '')
      const key = inputElements.key.value
      const result = await removeItemFromFirebase(key, map.ENTITIES)
      deleteRow(key)
      closeRightPanel()
      modal.hide()
      spinnerEl.classList.add('d-none')
      delBtnModal.removeAttribute('disabled')
      showOkToast('Запись успешно удалена')
      console.log(`${result}`)
    } catch (error) {
      console.log(`Ошибка при удалении записи: ${error}`)
      showErrorToast('Не удалось удалить запись')
    }
  }
}

function validateOnSave (formData) {
  let isValid = true
  const dateEl = maskElements.date.iMask.el.input
  const date = formData.date

  if (date && isDateInFuture(date)) {
    isValid = updateValidationState(dateEl, false, 'лекция не может быть в будущем')
    return validateTagifyFields(formData) && isValid
  }
  isValid = updateValidationState(dateEl, formData.date, 'неверный формат даты')
  return validateTagifyFields(formData) && isValid
}

const isDateInFuture = (dateString) => {
  // Преобразуем строку в объект DateTime
  const inputDate = DateTime.fromFormat(dateString, 'yyyy.MM.dd')

  // Получаем текущую дату
  const today = DateTime.now().startOf('day')

  // Сравниваем даты
  return inputDate > today
}

function validateTagifyFields (formData) {
  let isValid = true
  for (const [key, value] of Object.entries(map)) {
    if (value.isRequired) {
      const tagify = tagifyElements[key]
      const fieldData = formData[key]
      isValid = updateValidationState(tagify.DOM.originalInput, fieldData, 'обязательное поле', tagify) && isValid
    }
  }
  return isValid
}

export function updateValidationState (el, isValid, message = '', tagify) {
  const feedbackElement = el.nextElementSibling
  el.classList.toggle('is-invalid', !isValid)
  feedbackElement.textContent = isValid ? '' : message
  if (tagify) {
    tagify.DOM.scope.style.borderColor = isValid ? '' : '#DC3545'
  }
  return isValid
}

export function resetAllFields () {
  for (const key in inputElements) {
    inputElements[key].value = ''
  }
  inputElements.description.textContent = '' // это нужно, поскольку используется не textarea, обычный div, который симулирует текстовое поле, чтобы работала подсветка по поиску
  for (const role of Object.keys(map)) {
    if (!map[role].listName) continue
    const tagify = tagifyElements[role]
    tagify.removeAllTags()
    if (map[role].isRequired) updateValidationState(tagify.DOM.originalInput, true, '', tagify)
  }

  resetMaskValidationUI(true)
}

export function resetMaskValidationUI (resetValue = false) {
  const iMaskDate = maskElements.date.iMask
  if (resetValue) {
    iMaskDate.value = ''
    maskElements.duration.iMask.value = ''
  }
  updateValidationState(iMaskDate.el.input, true, '')
}
