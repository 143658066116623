// import { Howl, Howler } from 'howler'

export function initializeAudioPlayer (audioSrc, containerId) {
  const container = document.getElementById(containerId)

  // Создаем структуру HTML для аудиоплеера
  container.innerHTML = `
    <div class="audio-player">
      <audio id="audio" src="${audioSrc}" preload="auto"></audio>
      <div class="play-pause-btn" id="play-pause-btn"><i class="bi bi-play-circle"></i></div>
      <div class="time-display" id="current-time">0:00</div>
      <div class="seek-bar" id="seek-bar">
        <input type="range" id="progress" value="0" min="0" max="100" step="1">
      </div>
      <div class="time-display" id="total-time">0:00</div>
     <div class="volume-control">
        <i class="bi bi-volume-up" id="volume-icon"></i>
        <input type="range" id="volume" value="100" min="0" max="100" step="1">
      </div>
    </div>
  `
  // Элементы управления
  const audio = container.querySelector('#audio')
  const playPauseBtn = container.querySelector('#play-pause-btn')
  const progress = container.querySelector('#progress')
  const currentTime = container.querySelector('#current-time')
  const totalTime = container.querySelector('#total-time')
  const volumeControl = container.querySelector('#volume')
  const volumeIcon = container.querySelector('#volume-icon')

  // Форматирование времени
  function formatTime (seconds) {
    const minutes = Math.floor(seconds / 60)
    const secs = Math.floor(seconds % 60)
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`
  }

  function updateProgress () {
    const current = audio.currentTime
    const duration = audio.duration
    currentTime.textContent = formatTime(current)
    if (duration > 0) {
      progress.value = (current / duration) * 100
    }
  }

  // Обновление общего времени
  audio.addEventListener('loadedmetadata', () => {
    totalTime.textContent = formatTime(audio.duration)
  })

  // Обновление прогресса во время воспроизведения
  audio.addEventListener('timeupdate', updateProgress)

  // Перемотка по клику на seek bar
  //   progress.addEventListener('input', function () {
  //     const seekTo = (progress.value / 100) * audio.duration
  //     audio.currentTime = seekTo
  //   })

  // Перемотка по клику на seek bar
  progress.addEventListener('input', function () {
    const duration = audio.duration
    if (duration > 0 && !isNaN(duration)) {
      const seekTo = (progress.value / 100) * duration
      audio.currentTime = seekTo
    }
  })

  // Управление воспроизведением
  playPauseBtn.addEventListener('click', function () {
    if (audio.paused) {
      audio.play()
      playPauseBtn.innerHTML = '<i class="bi bi-pause-circle"></i>'
    } else {
      audio.pause()
      playPauseBtn.innerHTML = '<i class="bi bi-play-circle"></i>'
    }
  })

  // Показать или скрыть ползунок громкости
  volumeIcon.addEventListener('click', function () {
    volumeControl.style.display = volumeControl.style.display === 'block' ? 'none' : 'block'
  })

  // Скрыть ползунок громкости при клике вне его области
  document.addEventListener('click', function (event) {
    if (!volumeControl.contains(event.target) && !volumeIcon.contains(event.target)) {
      volumeControl.style.display = 'none'
    }
  })

  // Управление громкостью
  volumeControl.addEventListener('input', function () {
    audio.volume = volumeControl.value / 100
    if (audio.volume === 0) {
      volumeIcon.classList.replace('bi-volume-up', 'bi-volume-mute')
    } else if (audio.volume > 0.5) {
      volumeIcon.classList.replace('bi-volume-mute', 'bi-volume-up')
      volumeIcon.classList.replace('bi-volume-down', 'bi-volume-up')
    } else {
      volumeIcon.classList.replace('bi-volume-mute', 'bi-volume-down')
      volumeIcon.classList.replace('bi-volume-up', 'bi-volume-down')
    }
  })

  // Начальная установка громкости
  audio.volume = volumeControl.value / 100
  if (audio.volume === 0) {
    volumeIcon.classList.replace('bi-volume-up', 'bi-volume-mute')
  } else if (audio.volume > 0.5) {
    volumeIcon.classList.replace('bi-volume-mute', 'bi-volume-up')
    volumeIcon.classList.replace('bi-volume-down', 'bi-volume-up')
  } else {
    volumeIcon.classList.replace('bi-volume-mute', 'bi-volume-down')
    volumeIcon.classList.replace('bi-volume-up', 'bi-volume-down')
  }
}

export function stopAudio () {
  const audioElement = document.querySelector('#audio-player-container audio')
  if (audioElement) {
    audioElement.pause()
    audioElement.currentTime = 0
  }
}
